// Table
export const SET_SUBSCRIPTION_PAGE = "SET_SUBSCRIPTION_PAGE";
export const SET_SUBSCRIPTION_SIZE_PER_PAGE = "SET_SUBSCRIPTION_SIZE_PER_PAGE";
export const SET_SUBSCRIPTION_SEARCH = "SET_SUBSCRIPTION_SEARCH";
export const SET_SUBSCRIPTION_SORT_FIELD = "SET_SUBSCRIPTION_SORT_FIELD";
export const SET_SUBSCRIPTION_SORT_ORDER = "SET_SUBSCRIPTION_SORT_ORDER";
export const CLEAR_SUBSCRIPTION_FEATURES = "CLEAR_SUBSCRIPTION_FEATURES";

// Get Subscription
export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_SUBSCRIPTION_ERROR = "FETCH_SUBSCRIPTION_ERROR";

// Get New Subscription
export const FETCH_NEW_SUBSCRIPTION = "FETCH_NEW_SUBSCRIPTION";
export const FETCH_NEW_SUBSCRIPTION_SUCCESS = "FETCH_NEW_SUBSCRIPTION_SUCCESS";
export const FETCH_NEW_SUBSCRIPTION_ERROR = "FETCH_NEW_SUBSCRIPTION_ERROR";

// Get Country
export const FETCH_SUBSCRIPTION_COUNTRY = "FETCH_SUBSCRIPTION_COUNTRY";
export const FETCH_SUBSCRIPTION_COUNTRY_SUCCESS =
  "FETCH_SUBSCRIPTION_COUNTRY_SUCCESS";

// Get State
export const FETCH_SUBSCRIPTION_STATE = "FETCH_SUBSCRIPTION_STATE";
export const FETCH_SUBSCRIPTION_STATE_SUCCESS =
  "FETCH_SUBSCRIPTION_STATE_SUCCESS";

// Get New Subscription
export const VERIFY_COUPON = "VERIFY_COUPON";
export const VERIFY_COUPON_SUCCESS = "VERIFY_COUPON_SUCCESS";
export const VERIFY_COUPON_ERROR = "VERIFY_COUPON_ERROR";
export const VERIFY_COUPON_V2 = "VERIFY_COUPON_V2";

// Submit Subscription
export const SUBMIT_SUBSCRIPTION = "SUBMIT_SUBSCRIPTION";
export const SUBMIT_SUBSCRIPTION_SUCCESS = "SUBMIT_SUBSCRIPTION_SUCCESS";
export const SUBMIT_SUBSCRIPTION_ERROR = "SUBMIT_SUBSCRIPTION_ERROR";

// Get Edit Subscription
export const EDIT_SUBSCRIPTION = "EDIT_SUBSCRIPTION";
export const EDIT_SUBSCRIPTION_SUCCESS = "EDIT_SUBSCRIPTION_SUCCESS";
export const EDIT_SUBSCRIPTION_ERROR = "EDIT_SUBSCRIPTION_ERROR";

export const FETCH_TOTAL_SUBSCRIPTION = "FETCH_TOTAL_SUBSCRIPTION";
export const FETCH_TOTAL_SUBSCRIPTION_SUCCESS =
  "FETCH_TOTAL_SUBSCRIPTION_SUCCESS";
export const SET_SUBSCRIPTION_STATUS = "SET_SUBSCRIPTION_STATUS";
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";

export const RESET_DISCOUNT = "RESET_DISCOUNT";

export const GET_SUBSCRIPTION_DATA = "GET_SUBSCRIPTION_DATA";
export const GET_SUBSCRIPTION_DATA_SUCCESS = "GET_SUBSCRIPTION_DATA_SUCCESS";
export const CLEAR_SUB_STATE = "CLEAR_SUB_STATE";

// Send Charge Receipt
export const SEND_RECEIPT = "SEND_RECEIPT";
export const SEND_RECEIPT_SUCCESS = "SEND_RECEIPT_SUCCESS";

// Send Refund Receipt
export const SEND_REFUND_RECEIPT = "SEND_REFUND_RECEIPT";
export const SEND_REFUND_RECEIPT_SUCCESS = "SEND_REFUND_RECEIPT_SUCCESS";

export const SET_BUTTON_TYPE = "SET_BUTTON_TYPE";
export const SET_SUBSCRIPTION_TOTAL_VALUE = "SET_SUBSCRIPTION_TOTAL_VALUE";
