import {
  ADD_EDIT_SERVICE,
  ADD_EDIT_SERVICE_ERROR,
  ADD_EDIT_SERVICE_SUCCESS,
  ADD_EDIT_SITE,
  ADD_EDIT_SITE_ERROR,
  ADD_EDIT_SITE_SUCCESS,
  ADD_EDIT_SMS_SERVICE,
  ADD_EDIT_SMS_SERVICE_ERROR,
  ADD_EDIT_SMS_SERVICE_SUCCESS,
  CLEAR_AFFILIATE,
  CLEAR_AFFILIATE_MODAL_PAYLOAD,
  CLEAR_AFFILIATE_PAYOUT,
  CLEAR_AFFILIATE_REFERRAL,
  CLEAR_AFFILIATE_VISIT,
  CLEAR_MENU_DATA,
  CLEAR_SERVICE,
  DELETE_APP_MENU,
  DELETE_APP_MENU_SUCCESS,
  DELETE_EMAIL_SERVICE,
  DELETE_EMAIL_SERVICE_ERROR,
  DELETE_EMAIL_SERVICE_SUCCESS,
  DELETE_SITE_PAYMENT_SERVICE,
  DELETE_SITE_PAYMENT_SERVICE_ERROR,
  DELETE_SITE_PAYMENT_SERVICE_SUCCESS,
  FETCH_AFFILIATE_MODAL_DATA,
  FETCH_AFFILIATE_PAYOUT,
  FETCH_AFFILIATE_PAYOUT_ERROR,
  FETCH_AFFILIATE_PAYOUT_SUCCESS,
  FETCH_AFFILIATE_REFERRAL,
  FETCH_AFFILIATE_REFERRAL_ERROR,
  FETCH_AFFILIATE_REFERRAL_SUCCESS,
  FETCH_HIGHEST_CONVERTING_URLS,
  FETCH_HIGHEST_CONVERTING_URLS_ERROR,
  FETCH_HIGHEST_CONVERTING_URLS_SUCCESS,
  FETCH_LATEST_AFFILIATE_USERS,
  FETCH_LATEST_AFFILIATE_USERS_ERROR,
  FETCH_LATEST_AFFILIATE_USERS_SUCCESS,
  FETCH_MOST_VALUABLE_AFFILIATES,
  FETCH_MOST_VALUABLE_AFFILIATES_ERROR,
  FETCH_MOST_VALUABLE_AFFILIATES_SUCCESS,
  FETCH_PAYMENT_SERVICE,
  FETCH_PAYMENT_SERVICES_SUCCESS,
  FETCH_PAYMENT_SERVICE_ERROR,
  FETCH_RATE_CONFIGURATION,
  FETCH_RATE_CONFIGURATION_ERROR,
  FETCH_RATE_CONFIGURATION_SUCCESS,
  FETCH_RECENT_REFERRALS,
  FETCH_RECENT_REFERRALS_ERROR,
  FETCH_RECENT_REFERRALS_SUCCESS,
  FETCH_SERVER_CREDENTIAL,
  FETCH_SERVER_CREDENTIAL_ERROR,
  FETCH_SERVER_CREDENTIAL_SUCCESS,
  FETCH_SITES,
  FETCH_SITES_SUCCESS,
  FETCH_SMS_SERVER_CREDENTIAL,
  FETCH_SMS_SERVER_CREDENTIAL_ERROR,
  FETCH_SMS_SERVER_CREDENTIAL_SUCCESS,
  FILTER_TYPE_AFFILIATE,
  GET_ACTIVE_TYPE,
  GET_AFFILIATE,
  GET_AFFILIATE_ERROR,
  GET_AFFILIATE_SUCCESS,
  GET_ALL_APP_MENU,
  GET_ALL_APP_MENU_ERROR,
  GET_ALL_APP_MENU_SUCCESS,
  GET_DASHBOARD_PAGE_CONTACT,
  GET_DASHBOARD_PAGE_CONTACT_ERROR,
  GET_DASHBOARD_PAGE_CONTACT_SUCCESS,
  GET_SERVICE,
  GET_SERVICE_ERROR,
  GET_SERVICE_SUCCESS,
  GET_SMS_SERVICE,
  GET_SMS_SERVICE_ERROR,
  GET_SMS_SERVICE_SUCCESS,
  GET_SYSTEM_CONFIG,
  GET_SYSTEM_CONFIG_ERROR,
  GET_SYSTEM_CONFIG_SUCCESS,
  INTAKE_LIST,
  INTAKE_LIST_ERROR,
  INTAKE_LIST_LOADING,
  INTAKE_LIST_PAGE,
  INTAKE_LIST_SEARCH,
  INTAKE_LIST_SIZEPER_PAGE,
  INTAKE_LIST_SORT_FIELD,
  INTAKE_LIST_SORT_ORDER,
  LIST_AFFILIATE_VISIT_DATA,
  LIST_AFFILIATE_VISIT_DATA_ERROR,
  LIST_AFFILIATE_VISIT_DATA_SUCCESS,
  ORDER_APP_MENU,
  ORDER_APP_MENU_ERROR,
  ORDER_APP_MENU_SUCCESS,
  SAVE_APP_MENU,
  SAVE_APP_MENU_ERROR,
  SAVE_APP_MENU_SUCCESS,
  SAVE_HOME_DASHBOARD_DETAILS,
  SAVE_HOME_DASHBOARD_DETAILS_ERROR,
  SAVE_HOME_DASHBOARD_DETAILS_SUCCESS,
  SAVE_INTAKE_DATA_LOADING,
  SAVE_INTAKE_DATA_SUCCESS,
  SAVE_PAYMENT_SERVICE,
  SAVE_PAYMENT_SERVICE_ERROR,
  SAVE_PAYMENT_SERVICE_SUCCESS,
  SAVE_RATE_CONFIGURATION,
  SAVE_RATE_CONFIGURATION_ERROR,
  SAVE_RATE_CONFIGURATION_SUCCESS,
  SAVE_SYSTEM_CONFIG,
  SAVE_SYSTEM_CONFIG_ERROR,
  SAVE_SYSTEM_CONFIG_SUCCESS,
  SET_AFFILIATE_CALCULATE_LOADER,
  SET_AFFILIATE_DATE_RANGE,
  SET_AFFILIATE_FILTER,
  SET_AFFILIATE_LOADER,
  SET_AFFILIATE_MODAL_DATA,
  SET_AFFILIATE_MODAL_DATA_LOADING,
  SET_AFFILIATE_MODAL_DATA_PAGE_NO,
  SET_AFFILIATE_MODAL_DATA_PAGE_RECORD,
  SET_AFFILIATE_MODAL_DATA_SEARCH,
  SET_AFFILIATE_MODAL_DATA_SORT_FIELD,
  SET_AFFILIATE_MODAL_DATA_SORT_ORDER,
  SET_AFFILIATE_PAGE,
  SET_AFFILIATE_PAYNOW_LOADER,
  SET_AFFILIATE_PAYOUT_END_DATE,
  SET_AFFILIATE_PAYOUT_PAGE,
  SET_AFFILIATE_PAYOUT_PAYMENT_METHOD,
  SET_AFFILIATE_PAYOUT_SEARCH,
  SET_AFFILIATE_PAYOUT_SIZE_PER_PAGE,
  SET_AFFILIATE_PAYOUT_SORT_FIELD,
  SET_AFFILIATE_PAYOUT_SORT_ORDER,
  SET_AFFILIATE_PAYOUT_START_DATE,
  SET_AFFILIATE_PAYOUT_STATUS,
  SET_AFFILIATE_REFFERAL_LOADER,
  SET_AFFILIATE_SEARCH,
  SET_AFFILIATE_SIZE_PER_PAGE,
  SET_AFFILIATE_SORT_FIELD,
  SET_AFFILIATE_SORT_ORDER,
  SET_AFFILIATE_VISIT_PAGE,
  SET_AFFILIATE_VISIT_SEARCH,
  SET_AFFILIATE_VISIT_SIZE_PER_PAGE,
  SET_AFFILIATE_VISIT_SORT_FIELD,
  SET_AFFILIATE_VISIT_SORT_ORDER,
  SET_INTAKE_FILTER_BY_MASTERMIND,
  SET_INTAKE_FILTER_BY_STATUS,
  SET_REFERRAL_PAGE,
  SET_REFERRAL_SEARCH,
  SET_REFERRAL_SIZE_PER_PAGE,
  SET_REFERRAL_SORT_FIELD,
  SET_REFERRAL_SORT_ORDER,
  SET_REFERRAL_STATUS,
  SET_REFERRAL_TYPE,
  SET_SITE_ID,
  SYSTEM_PAGE_PAGELINK,
  SYSTEM_PAGE_PAGELINK_ERROR,
  SYSTEM_PAGE_PAGELINK_SUCCESS,
  FETCH_AFFILIATE_WIDGET_MODAL_DATA,
  SET_AFFILIATE_WIDGET_MODAL_DATA,
  SET_AFFILIATE_WIDGET_MODAL_DATA_LOADING,
  SET_AFFILIATE_WIDGET_MODAL_DATA_PAGE_NO,
  SET_AFFILIATE_WIDGET_MODAL_DATA_PAGE_RECORD,
  SET_AFFILIATE_WIDGET_MODAL_DATA_SEARCH,
  CLEAR_AFFILIATE_WIDGET_MODAL_PAYLOAD,
} from "./actionTypes";

export const setSiteId = id => ({
  type: SET_SITE_ID,
  id: id,
});

export const fetchPaymentService = id => ({
  type: FETCH_PAYMENT_SERVICE,
  id: id,
});
export const fetchPaymentServiceSuccess = data => ({
  type: FETCH_PAYMENT_SERVICES_SUCCESS,
  payload: data,
});
export const fetchPaymentServiceError = () => ({
  type: FETCH_PAYMENT_SERVICE_ERROR,
});
//Nandini changes 10/3/2023
export const fetchHomeDashboard = () => ({
  type: GET_DASHBOARD_PAGE_CONTACT,
});
export const fetchHomeDashboardSuccess = data => ({
  type: GET_DASHBOARD_PAGE_CONTACT_SUCCESS,
  payload: data,
});
export const fetchHomeDashboardError = () => ({
  type: GET_DASHBOARD_PAGE_CONTACT_ERROR,
});

export const saveHomeDashboard = data => ({
  type: SAVE_HOME_DASHBOARD_DETAILS,
  payload: data,
});
export const saveHomeDashboardSuccess = data => ({
  type: SAVE_HOME_DASHBOARD_DETAILS_SUCCESS,
  payload: data,
});
export const saveHomeDashboardError = () => ({
  type: SAVE_HOME_DASHBOARD_DETAILS_ERROR,
});
export const savePaymentService = payload => ({
  type: SAVE_PAYMENT_SERVICE,
  payload: payload,
});
export const savePaymentServiceSuccess = data => ({
  type: SAVE_PAYMENT_SERVICE_SUCCESS,
  payload: data,
});
export const savePaymentServiceError = () => ({
  type: SAVE_PAYMENT_SERVICE_ERROR,
});

export const getServices = () => ({
  type: GET_SERVICE,
});
export const getServicesSuccess = data => ({
  type: GET_SERVICE_SUCCESS,
  payload: data,
});
export const getServicesError = () => ({
  type: GET_SERVICE_ERROR,
});

export const clearServices = () => ({
  type: CLEAR_SERVICE,
});

export const addEditService = data => ({
  type: ADD_EDIT_SERVICE,
  payload: data,
});
export const addEditServiceSuccess = data => ({
  type: ADD_EDIT_SERVICE_SUCCESS,
  payload: data,
});
export const addEditServiceError = () => ({
  type: ADD_EDIT_SERVICE_ERROR,
});

export const deleteEmailService = data => ({
  type: DELETE_EMAIL_SERVICE,
  payload: data,
});
export const deleteEmailServiceSuccess = () => ({
  type: DELETE_EMAIL_SERVICE_SUCCESS,
});
export const deleteEmailServiceError = () => ({
  type: DELETE_EMAIL_SERVICE_ERROR,
});

export const fetchServerCredential = () => ({
  type: FETCH_SERVER_CREDENTIAL,
});

export const fetchServerCredentialSuccess = data => ({
  type: FETCH_SERVER_CREDENTIAL_SUCCESS,
  payload: data,
});

export const fetchServerCredentialError = () => ({
  type: FETCH_SERVER_CREDENTIAL_ERROR,
});

export const deleteSitePaymentService = id => ({
  type: DELETE_SITE_PAYMENT_SERVICE,
  id: id,
});

export const deleteSitePaymentServiceSuccess = () => ({
  type: DELETE_SITE_PAYMENT_SERVICE_SUCCESS,
});

export const deleteSitePaymentServiceError = () => ({
  type: DELETE_SITE_PAYMENT_SERVICE_ERROR,
});

export const addEditSite = data => ({
  type: ADD_EDIT_SITE,
  payload: data,
});
export const addEditSiteSuccess = data => ({
  type: ADD_EDIT_SITE_SUCCESS,
  payload: data,
});
export const addEditSiteError = () => ({
  type: ADD_EDIT_SITE_ERROR,
});

export const fetchSites = data => ({
  type: FETCH_SITES,
  payload: data,
});
export const fetchSitesSuccess = data => ({
  type: FETCH_SITES_SUCCESS,
  payload: data,
});

/** App Menu list */
export const getAllMenuList = () => ({
  type: GET_ALL_APP_MENU,
});
export const getAllMenuSuccess = data => ({
  type: GET_ALL_APP_MENU_SUCCESS,
  payload: data,
});
export const getAllMenuError = () => ({
  type: GET_ALL_APP_MENU_ERROR,
});

export const saveApplicationMenu = data => ({
  type: SAVE_APP_MENU,
  payload: data,
});
export const saveApplicationMenuSuccess = data => ({
  type: SAVE_APP_MENU_SUCCESS,
  payload: data,
});
export const saveApplicationMenuError = () => ({
  type: SAVE_APP_MENU_ERROR,
});

/** delete app menu */
export const deleteApplicationMenu = data => ({
  type: DELETE_APP_MENU,
  payload: data,
});

export const deleteApplicationMenuSuccess = data => {
  return {
    type: DELETE_APP_MENU_SUCCESS,
    payload: data,
  };
};

export const orderApplicationMenu = data => ({
  type: ORDER_APP_MENU,
  payload: data,
});
export const orderApplicationMenuSuccess = data => ({
  type: ORDER_APP_MENU_SUCCESS,
  payload: data,
});
export const orderApplicationMenuError = () => ({
  type: ORDER_APP_MENU_ERROR,
});

/** 25-02-22 */

export const getSmsServices = data => ({
  type: GET_SMS_SERVICE,
  payload: data,
});
export const getSmsServicesSuccess = data => ({
  type: GET_SMS_SERVICE_SUCCESS,
  payload: data,
});
export const getSmsServicesError = () => ({
  type: GET_SMS_SERVICE_ERROR,
});

export const fetchSmsServerCredential = () => ({
  type: FETCH_SMS_SERVER_CREDENTIAL,
});

export const fetchSmsServerCredentialSuccess = data => ({
  type: FETCH_SMS_SERVER_CREDENTIAL_SUCCESS,
  payload: data,
});

export const fetchSmsServerCredentialError = () => ({
  type: FETCH_SMS_SERVER_CREDENTIAL_ERROR,
});

export const addEditSmsService = data => ({
  type: ADD_EDIT_SMS_SERVICE,
  payload: data,
});
export const addEditSmsServiceSuccess = data => ({
  type: ADD_EDIT_SMS_SERVICE_SUCCESS,
  payload: data,
});
export const addEditSmsServiceError = () => ({
  type: ADD_EDIT_SMS_SERVICE_ERROR,
});

//Policy
export const getSystemConfiguration = data => ({
  type: GET_SYSTEM_CONFIG,
  payload: data,
});

export const getSystemConfigurationSuccess = data => ({
  type: GET_SYSTEM_CONFIG_SUCCESS,
  payload: data,
});

export const getSystemConfigurationError = () => ({
  type: GET_SYSTEM_CONFIG_ERROR,
});

export const saveSystemConfig = data => ({
  type: SAVE_SYSTEM_CONFIG,
  payload: data,
});

export const saveSystemConfigSuccess = () => ({
  type: SAVE_SYSTEM_CONFIG_SUCCESS,
});

export const saveSystemConfigError = () => ({
  type: SAVE_SYSTEM_CONFIG_ERROR,
});
//LIST Affiliate
export const getAffiliate = data => ({
  type: GET_AFFILIATE,
  payload: data,
});

export const getAffiliateSuccess = data => ({
  type: GET_AFFILIATE_SUCCESS,
  payload: data,
});

export const getAffiliateError = () => ({
  type: GET_AFFILIATE_ERROR,
});

export const getActiveType = data => ({
  type: GET_ACTIVE_TYPE,
  payload: data,
});
export const setAffiliatePage = data => ({
  type: SET_AFFILIATE_PAGE,
  payload: data,
});
export const setAffiliateFilter = data => ({
  type: SET_AFFILIATE_FILTER,
  payload: data,
});

export const setAffiliateFilterType = data => ({
  type: FILTER_TYPE_AFFILIATE,
  payload: data,
});

export const listSystemPageLink = data => ({
  type: SYSTEM_PAGE_PAGELINK,
  payload: data,
});

export const listSystemPageLinkSuccess = data => ({
  type: SYSTEM_PAGE_PAGELINK_SUCCESS,
  payload: data,
});

export const listSystemPageLinkError = () => ({
  type: SYSTEM_PAGE_PAGELINK_ERROR,
});

export const setAffiliateSearch = data => ({
  type: SET_AFFILIATE_SEARCH,
  payload: data,
});

export const setAffiliateSizePerPage = data => ({
  type: SET_AFFILIATE_SIZE_PER_PAGE,
  payload: data,
});

export const setAffiliateSortField = data => ({
  type: SET_AFFILIATE_SORT_FIELD,
  payload: data,
});

export const setAffiliateSortOrder = data => ({
  type: SET_AFFILIATE_SORT_ORDER,
  payload: data,
});

export const clearAffiliate = () => ({
  type: CLEAR_AFFILIATE,
});

//rate configuration

export const fetchRateConfiguration = () => ({
  type: FETCH_RATE_CONFIGURATION,
});
export const fetchRateConfigurationSuccess = data => ({
  type: FETCH_RATE_CONFIGURATION_SUCCESS,
  payload: data,
});
export const fetchRateConfigurationError = () => ({
  type: FETCH_RATE_CONFIGURATION_ERROR,
});

export const saveRateConfiguration = payload => ({
  type: SAVE_RATE_CONFIGURATION,
  payload: payload,
});
export const saveRateConfigurationSuccess = data => ({
  type: SAVE_RATE_CONFIGURATION_SUCCESS,
  payload: data,
});
export const saveRateConfigurationError = () => ({
  type: SAVE_RATE_CONFIGURATION_ERROR,
});
//Affiliate Dashboard
export const fetchLatestAffiliateUsers = payload => ({
  type: FETCH_LATEST_AFFILIATE_USERS,
  payload: payload,
});
export const fetchLatestAffiliateUsersSuccess = data => ({
  type: FETCH_LATEST_AFFILIATE_USERS_SUCCESS,
  payload: data,
});
export const fetchLatestAffiliateUsersError = () => ({
  type: FETCH_LATEST_AFFILIATE_USERS_ERROR,
});

//Totals
export const fetchHighestConvertingUrls = payload => ({
  type: FETCH_HIGHEST_CONVERTING_URLS,
  payload: payload,
});
export const fetchHighestConvertingUrlsSuccess = data => ({
  type: FETCH_HIGHEST_CONVERTING_URLS_SUCCESS,
  payload: data,
});
export const fetchHighestConvertingUrlsError = () => ({
  type: FETCH_HIGHEST_CONVERTING_URLS_ERROR,
});

//Most valuable affiliates
export const fetchMostValuableAffiliate = payload => ({
  type: FETCH_MOST_VALUABLE_AFFILIATES,
  payload: payload,
});
export const fetchMostValuableAffiliateSuccess = data => ({
  type: FETCH_MOST_VALUABLE_AFFILIATES_SUCCESS,
  payload: data,
});
export const fetchMostValuableAffiliateError = () => ({
  type: FETCH_MOST_VALUABLE_AFFILIATES_ERROR,
});

//Recent Referrals
export const fetchRecentReferrals = payload => ({
  type: FETCH_RECENT_REFERRALS,
  payload: payload,
});
export const fetchRecentReferralsSuccess = data => ({
  type: FETCH_RECENT_REFERRALS_SUCCESS,
  payload: data,
});
export const fetchRecentReferralsError = () => ({
  type: FETCH_RECENT_REFERRALS_ERROR,
});

//List payout
export const fetchAffiliatePayout = data => ({
  type: FETCH_AFFILIATE_PAYOUT,
  payload: data,
});

export const fetchAffiliatePayoutSuccess = data => ({
  type: FETCH_AFFILIATE_PAYOUT_SUCCESS,
  payload: data,
});

export const fetchAffiliatePayoutError = () => ({
  type: FETCH_AFFILIATE_PAYOUT_ERROR,
});

export const fetchAffiliatePayoutPage = data => ({
  type: SET_AFFILIATE_PAYOUT_PAGE,
  payload: data,
});
export const fetchAffiliatePayoutPaymentMethod = data => ({
  type: SET_AFFILIATE_PAYOUT_PAYMENT_METHOD,
  payload: data,
});
export const fetchAffiliatePayoutStatus = data => ({
  type: SET_AFFILIATE_PAYOUT_STATUS,
  payload: data,
});
export const fetchAffiliatePayoutStartDate = data => ({
  type: SET_AFFILIATE_PAYOUT_START_DATE,
  payload: data,
});
export const fetchAffiliatePayoutEndDate = data => ({
  type: SET_AFFILIATE_PAYOUT_END_DATE,
  payload: data,
});
export const fetchAffiliatePayoutSearch = data => ({
  type: SET_AFFILIATE_PAYOUT_SEARCH,
  payload: data,
});

export const fetchAffiliatePayoutPerPage = data => ({
  type: SET_AFFILIATE_PAYOUT_SIZE_PER_PAGE,
  payload: data,
});

export const fetchAffiliatePayoutSortField = data => ({
  type: SET_AFFILIATE_PAYOUT_SORT_FIELD,
  payload: data,
});

export const fetchAffiliatePayoutSortOrder = data => ({
  type: SET_AFFILIATE_PAYOUT_SORT_ORDER,
  payload: data,
});

export const clearAffiliatePayout = () => ({
  type: CLEAR_AFFILIATE_PAYOUT,
});

// Affiliate Visit
export const listAffiliateVisitData = data => ({
  type: LIST_AFFILIATE_VISIT_DATA,
  payload: data,
});
export const listAffiliateVisitDataSuccess = data => ({
  type: LIST_AFFILIATE_VISIT_DATA_SUCCESS,
  payload: data,
});
export const listAffiliateVisitDataError = () => ({
  type: LIST_AFFILIATE_VISIT_DATA_ERROR,
});

export const setAffiliateVisitPage = data => ({
  type: SET_AFFILIATE_VISIT_PAGE,
  payload: data,
});

export const setAffiliateVisitSearch = data => {
  return {
    type: SET_AFFILIATE_VISIT_SEARCH,
    payload: data,
  };
};

export const setAffiliateVisitSizePerPage = data => ({
  type: SET_AFFILIATE_VISIT_SIZE_PER_PAGE,
  payload: data,
});
export const setAffiliateVisitSortField = data => ({
  type: SET_AFFILIATE_VISIT_SORT_FIELD,
  payload: data,
});
export const setAffiliateVisitSortOrder = data => ({
  type: SET_AFFILIATE_VISIT_SORT_ORDER,
  payload: data,
});

export const clearAffiliateVisit = () => ({
  type: CLEAR_AFFILIATE_VISIT,
});

//Affiliate Referral
export const fetchAffiliateReferral = data => ({
  type: FETCH_AFFILIATE_REFERRAL,
  payload: data,
});
export const fetchAffiliateReferralSuccess = data => ({
  type: FETCH_AFFILIATE_REFERRAL_SUCCESS,
  payload: data,
});
export const fetchAffiliateReferralError = () => ({
  type: FETCH_AFFILIATE_REFERRAL_ERROR,
});
export const setReferralPage = data => ({
  type: SET_REFERRAL_PAGE,
  payload: data,
});
export const setReferralSearch = data => ({
  type: SET_REFERRAL_SEARCH,
  payload: data,
});
export const setReferralSizePerPage = data => ({
  type: SET_REFERRAL_SIZE_PER_PAGE,
  payload: data,
});
export const setReferralSortField = data => ({
  type: SET_REFERRAL_SORT_FIELD,
  payload: data,
});
export const setReferralSortOrder = data => ({
  type: SET_REFERRAL_SORT_ORDER,
  payload: data,
});
export const setReferralType = data => ({
  type: SET_REFERRAL_TYPE,
  payload: data,
});
export const setReferralStatus = data => ({
  type: SET_REFERRAL_STATUS,
  payload: data,
});

export const clearAffiliateReferral = () => ({
  type: CLEAR_AFFILIATE_REFERRAL,
});
export const setAffiliateLoader = data => ({
  type: SET_AFFILIATE_LOADER,
  payload: data,
});
export const setAffiliateRefferalLoader = data => ({
  type: SET_AFFILIATE_REFFERAL_LOADER,
  payload: data,
});
export const setAffiliatePaynowLoader = data => ({
  type: SET_AFFILIATE_PAYNOW_LOADER,
  payload: data,
});
export const setAffiliateCalculateLoader = data => ({
  type: SET_AFFILIATE_CALCULATE_LOADER,
  payload: data,
});

export const setAffiliateDateRange = data => ({
  type: SET_AFFILIATE_DATE_RANGE,
  payload: data,
});

export const clearMenuData = () => ({
  type: CLEAR_MENU_DATA,
});

export const fetchAffiliateModalData = data => ({
  type: FETCH_AFFILIATE_MODAL_DATA,
  payload: data,
});

export const setAffiliateModalData = data => ({
  type: SET_AFFILIATE_MODAL_DATA,
  payload: data,
});

export const setAffiliateModalDataLoading = data => ({
  type: SET_AFFILIATE_MODAL_DATA_LOADING,
  payload: data,
});

export const setAffiliateModalDataPageNo = data => ({
  type: SET_AFFILIATE_MODAL_DATA_PAGE_NO,
  payload: data,
});

export const setAffiliateModalDataPageRecord = data => ({
  type: SET_AFFILIATE_MODAL_DATA_PAGE_RECORD,
  payload: data,
});

export const setAffiliateModalDataSortField = data => ({
  type: SET_AFFILIATE_MODAL_DATA_SORT_FIELD,
  payload: data,
});

export const setAffiliateModalDataSortOrder = data => ({
  type: SET_AFFILIATE_MODAL_DATA_SORT_ORDER,
  payload: data,
});

export const setAffiliateModalDataSearch = data => ({
  type: SET_AFFILIATE_MODAL_DATA_SEARCH,
  payload: data,
});

export const clearAffiliateModalPayload = data => ({
  type: CLEAR_AFFILIATE_MODAL_PAYLOAD,
  payload: data,
});

export const fetchAffiliateWidgetModalData = (url, data) => ({
  type: FETCH_AFFILIATE_WIDGET_MODAL_DATA,
  url: url,
  payload: data,
});

export const setAffiliateWidgetModalData = data => ({
  type: SET_AFFILIATE_WIDGET_MODAL_DATA,
  payload: data,
});

export const setAffiliateWidgetModalDataLoading = data => ({
  type: SET_AFFILIATE_WIDGET_MODAL_DATA_LOADING,
  payload: data,
});

export const setAffiliateWidgetModalDataPageNo = data => ({
  type: SET_AFFILIATE_WIDGET_MODAL_DATA_PAGE_NO,
  payload: data,
});

export const setAffiliateWidgetModalDataPageRecord = data => ({
  type: SET_AFFILIATE_WIDGET_MODAL_DATA_PAGE_RECORD,
  payload: data,
});

export const setAffiliateWidgetModalDataSearch = data => ({
  type: SET_AFFILIATE_WIDGET_MODAL_DATA_SEARCH,
  payload: data,
});

export const clearAffiliateWidgetModalPayload = data => ({
  type: CLEAR_AFFILIATE_WIDGET_MODAL_PAYLOAD,
  payload: data,
});
//intake form listing

export const fetchIntakeList = data => ({
  type: INTAKE_LIST_LOADING,
  payload: data,
});
export const fetchIntakeListSuccess = data => ({
  type: INTAKE_LIST,
  payload: data,
});
export const fetchIntakeListError = () => ({
  type: INTAKE_LIST_ERROR,
});

export const setIntakeListPage = data => ({
  type: INTAKE_LIST_PAGE,
  payload: data,
});
export const setIntakeListSizePerPage = data => ({
  type: INTAKE_LIST_SIZEPER_PAGE,
  payload: data,
});

export const setIntakeListSearch = data => ({
  type: INTAKE_LIST_SEARCH,
  payload: data,
});
export const setIntakeListSortField = data => ({
  type: INTAKE_LIST_SORT_FIELD,
  payload: data,
});
export const setIntakeListSortOrder = data => ({
  type: INTAKE_LIST_SORT_ORDER,
  payload: data,
});

export const saveIntakeList = (data, cb) => ({
  type: SAVE_INTAKE_DATA_LOADING,
  payload: data,
  cb,
});

export const saveIntakeListSuccess = data => ({
  type: SAVE_INTAKE_DATA_SUCCESS,
  payload: data,
});
export const setIntakeFilterByMastermind = data => ({
  type: SET_INTAKE_FILTER_BY_MASTERMIND,
  payload: data,
});
export const setIntakeFilterByStatus = data => ({
  type: SET_INTAKE_FILTER_BY_STATUS,
  payload: data,
});
