//LOGIN
export const LOGIN = "/login";
export const ACCESS_USER_ACCOUNT = "/userAccountAccess";

//Users
export const LIST_ALL_USERS = "/listUser";
export const UPDATE_USER_PROFILE = "/addUpdateUserProfile";
export const DELETE_USER = "/deleteUser";
export const FETCH_USER_PROFILE = "/getUserProfileDetail";
export const FETCH_USER_PROFILE_BY_ID = "/getUserProfileDetailForAdmin";
export const EMAIL_RESET_USER = "/resetUserPassword";
export const COPY_RESET_PASSWORD_LINK_URL = "/copyResetPasswordLink";
export const CHANGE_CUSTOMER_PASSWORD = "/changeCustomerPassword";
export const FETCH_TOTAL_USERS_URL = "/getTotalUsers";
export const LIST_USER_TYPE_VISE = "/getAllUsersTypeWise";
export const LIST_CIRCLE_SUPPORT_TICKETS = "/listCircleSupportTickets";
export const SUPPORT_TICKETS_DETAILS = "/getCircleSupportTicketDetails";
export const ADD_SUPPORT_TICKET_COMMENTS = "/saveCircleSupportTicketComment";
export const DELETE_SUPPORT_TICKET_COMMENTS =
  "/deleteCircleSupportTicketComment";

export const CHANGE_USER_NAME_URL = "/updateEmailOrUsername";

//Coupon
export const SAVE_COUPON = "/saveCoupon";
export const GET_COUPON = "/getCouponDetails";
export const LIST_ALL_COUPONS = "/listAllCoupons";
export const DELETE_COUPON = "/deleteCoupon";

//Products
export const LIST_ALL_PRODUCTS = "/listAllProducts";
export const GET_PRODUCT = "/getAllProducts";
export const LIST_ALL_PRODUCT_FOLDERS = "/listAllProductFolders";
export const UPDATE_PRODUCT = "/saveProducts";
export const PRODUCT_DETAILS = "/getProductDetails";
export const DELETE_SINGLE_PRODUCT = "/deleteProduct";
export const ADD_EDIT_SINGLE_PRODUCT_FOLDER = "/saveProductFolder";
export const GET_SINGLE_PRODUCT_FOLDER_DETAILS = "/getProductFolder";
export const DELETE_SINGLE_PRODUCT_FOLDER = "/deleteProductFolder";
export const LIST_ALL_PRODUCTS_VOLUMES_WITH_COUNT = "/listAllProductVolumes";

// Tools
export const LIST_ALL_VOLUME_FOLDERS = "/listAllVolumes";
export const SAVE_VOLUME_FOLDERS = "/saveVolume";
export const ADD_EDIT_SINGLE_VOLUME_PRODUCT_FOLDER = "/saveProductFolder";
export const GET_SINGLE_VOLUME_FOLDERS_DETAILS = "/getVolumeDetails";
export const DELETE_SINGLE_VOLUME_FOLDERS = "/deleteVolume";
export const DELETE_VOLUME_SERIES = "/deleteSeries";
export const LIST_ALL_SERIES = "/listAllSeries";
export const SAVE_VOLUME_SERIES = "/addSeries";
export const GET_SINGLE_VOLUME_SERIES_DETAILS = "/getSeriesDetails";
export const GET_SERIES_EDITOR_DETAILS = "/getSeriesEditorDetails";
export const SAVE_SERIES_EDITOR = "/updateSeriesDetails";
export const SORT_VOLUME = "/sortVolume";
export const SORT_PAGE_CONTENT_TYPE = "/sortPageContentType";
export const SORT_POST_CONTENT_TYPE = "/updatePostSortOrders";
export const SORT_SERIES_CONTENT_TYPE = "/updateSeriesSortOrders";
export const SAVE_AMAZON_INTERNAL_URL = "/saveAmazonInternals";
export const GET_AMAZON_INTERNALS_URL = "/getAmazonInternals";
//Email Services
export const LIST_ALL_SERVICES = "/listServiceCredentials";
export const GET_SERVICE_DETAILS = "/getServiceCredentials";
export const LIST_ALL_SITES_URL = "/listSite";
export const ADD_SERVICE_URL = "/addUpdateServiceCredentials";
export const DELETE_SERVICE_URL = "/deleteEmailSiteServiceCredentials";

//SMS Services
export const LIST_SMS_SERVICES_CREDENTIALS = "/getSmsServicesCredentials/";
export const FETCH_SMS_SERVERS_MASTER_DATA = "/getSmsServicesMasterData";
export const ADD_EDIT_SMS_SERVICE_URL = "/saveSmsServicesCredentials";

// Membership
export const GET_MEMBERSHIP_URL = "/listMembership";
export const SAVE_MEMBERSHIP_URL = "/saveMembership";
export const DELETE_MEMBERSHIP_URL = "/deleteMembership";

export const GET_PAGE_URL = "/getAllPages";
export const SAVE_USER_MEMBERSHIP_URL = "/saveUserMembership";
export const FETCH_USER_MEMBERSHIP_URL = "/getUserMembership";

// Member
export const GET_MEMBERS_URL = "/getActiveMembershipUsers";
export const CREATE_NEW_USER_MEMBERSHIP_URL = "/createNewUserMembership";
export const DELETE_MEMBERSHIP_USER_URL = "deleteUserMemberships";

//Setting
export const LIST_ALL_PAYMENT = "/listPaymentService";
export const ADD_PAYMENT_REQUEST = "/addPaymentService";
export const UPDATE_PAYMENT_REQUEST = "/updatePaymentService";
export const GET_PAYMENT_DETAIL = "/getPaymentServiceDetail";
export const DELETE_PAYMENT_GATEWAY = "/deletePaymentService";
export const GET_DASHBOARD_PAGE_CONTACT_API = "/getDashboardPageContect";
export const SAVE_DASHBOARD_PAGE_CONTACT_API = "/saveDashboardPageContect";

//emaiL
export const LIST_ALL_EMAIL_TEMPLATE = "/listEmailDesign";
export const GET_EDIT_EMAIL_TEMPLATE = "/getEmailDesignDetail";
export const UPDATE_EMAIL_TEMPLATE = "/updateEmailDesign";
export const LIST_ALL_EMAIL_LOG = "/listEmailLogs";
export const RESEND_MAIL = "/resendMailAdmin";

//Subscription
export const LIST_ALL_SUBSCRIPTION = "/listSubscription";
export const CUSTOMER_LIST = "/customerList";
export const NEW_SUBSCRIPTION = "/createNewSubscription";
export const LIST_COUNTRY = "/listCountry";
export const LIST_STATE = "/listState";
export const COUPON_VERIFY = "/verifyCoupon";
export const COUPON_VERIFY_V2 = "/verifyCoupon_v2"
export const SUBMIT_ADMIN_SUBSCRIPTION = "/checkOutAdminSubscription";
export const FETCH_TOTAL_SUBSCRIPTION_URL = "/getTotalSubscriptions";
export const GET_SUBSCRIPTION_DETAIL = "/getSubscriptionDetails";
export const FETCH_TOTAL_ORDERS_URL = "/getTotalOrders";
export const GET_SUBSCRIPTION_DATA_URL = "getSubscriptionData";
export const REJECT_CANCEL_SUBSCRIPTION = "/rejectCancelSubscriptionRequest";
export const LIST_SUBSCRIPTION_TRANSACTION_URL = "/listSubscriptionTransaction";
export const FETCH_ORDERS_URL = "listOrder";
export const CHARGE_TRANSACTION_RECEIPT = "/chargeTransactionReceipt";
export const REFUND_TRANSACTION_RECEIPT = "/refundTransactionReceipt";
export const UPGRADE_DOWNGRADE_PRODUCT = "/upgradeDowngradeProduct";

//Order
export const LIST_ALL_ORDER = "/listOrder";
export const GET_ORDER_DETAILS = "/getOrderDetails";

// Curriculum category
export const LIST_ALL_CURRICULUM_CATEGORY = "/listAllCategories";

// Curriculum post
export const LIST_ALL_CURRICULUM_POST = "/listAllPosts";
export const LIST_ALL_OLD_CURRICULUM_POST = "/listAllOldPosts";
export const GET_CURRICULUM_POST_DETAIL = "/getPostDetails";
export const REMOVE_CURRICULUM_POST = "/deletePost";
export const UPDATE_CURRICULUM_POST = "/savePost";
export const GET_ALL_POSTS_CATEGORIES = "/getAllPostsCategories";
export const LIST_ALL_NEWS_FEEDS = "/getAllNewsFeeds";

// Curriculum post folder
export const UPDATE_CURRICULUM_POST_FOLDER = "/savePostFolders";
export const GET_POST_FOLDER_BY_ID = "/getPostFolders";
export const DELETE_POST_FOLDER_BY_ID = "/deletePostFolder";

//Curriculum Series
export const LIST_ALL_ANNOUNCEMENT_URL = "/listingAnnouncements";
export const ADD_EDIT_HUB_ANNOUNCEMENT_URL = "/saveAnnouncement";
export const LIST_ALL_CALENDER_ITEM_URL = "/listingCalendar";
export const DELETE_ANNOUNCEMENT_URL = "/deleteAnnouncement";
export const DELETE_CALENDER_ITEM_URL = "/deleteCalendar";
export const ADD_EDIT_CALENDER_ITEM_URL = "/saveCalendar";
export const UPDATE_SERIES_MEMORY_VERSE = "/updateSeriesMemoryVerseDetails";
export const GET_SERIES_MEMORY_VERSE_DETAILS = "/getSeriesMemoryVerseDetails";

// Curriculum post page
export const LIST_ALL_CURRICULUM_POST_PAGE = "/getPageDetailsForAdmin";
export const GET_ALL_PAGE_UI_COMPONENTS = "/getAllPageUiComponents";

//curriculum content
export const LIST_ALL_CONTENT_TYPES = "/listAllContentTypes";
export const GET_EDIT_CONTENT_TYPE_BY_ID = "/getContentTypeDetails";
export const EDIT_CONTENT_TYPE_BY_ID = "/updateContentTypeDetails";

//curriculum Pages
export const LIST_ALL_PAGES = "/listAllPages";
export const GET_PAGE = "/getPageDetailsForAdmin";
export const SAVE_PAGE = "/savePage";
export const DELETE_PAGE = "/deletePage";
export const GET_CONTENT_TYPE_ID_AND_TITLE = "/listAllContentTypeIdAndTitle";
export const GET_ALL_CONTENT_TYPE = "/listAllContentTypes";
export const PUBLISH_PAGE = "/publishPage";
export const LIST_ALL_PAGE_FOLDERS = "/listAllPageFolders";
export const SAVE_PAGE_FOLDERS = "/savePageFolder";
export const GET_SINGLE_PAGE_FOLDERS_DETAILS = "/getPageFolderDetails";
export const DELETE_SINGLE_PAGE_FOLDERS = "/deletePageFolder";
export const SAVE_PAGE_EDITOR = "/savePageEditor";
export const DUPLICATE_PAGE_API = "/duplicatePage";
export const SAVE_PAGE_CONNECTED_SERIES = "/savePageConnectedSeries";
export const SAVE_PAGE_CONNECTED_POSTS = "/savePageConnectedPosts";
export const GET_PAGE_CONNECTED_SERIES = "/getPageConnectedSeries";
export const GET_PAGE_CONNECTED_POSTS = "/getPageConnectedPosts";
export const GET_SERIES_EMAIL_DETAILS = "/getAllSeriesEmailPages";
export const SAVE_SERIES_EMAIL = "/saveSeriesEmail";
export const VALIDATE_LINK_KEYWORD = "/validateLinkKeyword";
export const GET_SERIES_TUTORIALS = "/getSeriesTutorials";
export const SAVE_SERIES_TUTORIAL = "/saveSeriesTutorials";

// Curriculum Tip Videos
export const ADD_UPDATE_TIP_VIDEOS = "/saveTipVideos";
export const LIST_ALL_TIP_VIDEOS = "/listAllTipVideos";
export const DELETE_TIP_VIDEO = "/deleteTipVideos";

//Subscription OverView
export const LIST_ALL_NET_SALES = "/getNetSalesChartData";
export const LIST_ALL_ORDER_SALE = "/getOrdersChartData";
export const LIST_ALL_AVERAGE_ORDER_VALUE = "/getAverageOrderValueChartData";
export const LIST_ALL_ITEM_SOLD = "/getItemSoldChartData";
export const LIST_ALL_RETURN = "/getReturnsChartData";
export const LIST_ALL_DISCOUNTED_ORDER = "/getDiscountedOrdersChartData";
export const LIST_ALL_GROSS_DISCOUNT = "/getGrossDiscountedChartData";
export const LIST_ALL_TOTAL_TAX = "/getTotalTaxChartData";
export const LIST_ALL_ORDER_TAX = "/getOrderTaxChartData";
export const LIST_ALL_SHIPPING_TAX = "/getShippingTaxChartData";
export const LIST_ALL_SHIPPING = "/getShippingChartData";
export const LIST_ALL_DOWNLOAD = "/getDownloadsChartData";
export const LIST_SUBSCRIPTION_PERFORMANCE = "/getSubscriptionPerformanceData";
export const LIST_ALL_TRANSACTIONS = "/listTransaction";

//Dashboard Api
export const CHART_NEW_REGISTER_USER = "/getNewRegisterUsersChartData";
export const CHART_CANCELLED_SUBSCRIPTION_CHART =
  "/getCancelSubscriptionChartData";
export const CHART_RENEWED_SUBSCRIPTION = "/getRenewedSubscriptionsChartData";
export const CHART_NEW_SUBSCRIPTION = "/getNewSubscriptionChartData";
export const DASHBOARD_CARD_DATA = "/dashBoardData";
export const DASHBOARD_TOP_VISITOR_DATA = "/getTopVisitorsChartData";
export const FETCH_EARNINGS_CHART = "/getEarningsChartData";
export const FETCH_TOP_SELLING_PRODUCTS_CHART = "/getTopSellingProductData";
export const FETCH_SALES_ANALYTICS_CHART = "getSalesAnalyticsData";
export const FETCH_SALES_ANALYTICS_CHART_V2 = "getSalesAnalyticsDataV2";
export const FETCH_ORDER_BY_PRODUCT_CHART = "/getOrderByProductData";
export const LIST_ALL_TOP_PRODUCT = "getTopProductData";
export const LIST_ALL_TOP_MEMBERSHIP = "getTopMembershipData";
export const LIST_ALL_SYSTEM_CONFIGURATION_URL = "listAllSystemConfigurations";
export const SAVE_SYSTEM_CONFIGURATION_URL = "saveSystemConfigurationsMeta";

// Notes
export const SAVE_NOTE = "/saveNotes";
export const LIST_NOTE = "/listNotes";
export const DELETE_NOTES = "/deleteNote";

// Common
export const GET_USER_NAMES_URL = "/getAllUserNames";

// Application
export const FETCH_PAYMENT_SERVICE_URL = "/listPaymentService";
export const SAVE_PAYMENT_SERVICE_URL = "/addPaymentService";
export const FETCH_SERVER_CREDENTIAL_URL = "/getServerCredentials";
export const LIST_ALL_PAYMENT_SERVICES = "/listMasterPaymentService";
export const DELETE_SITE_PAYMENT_SERVICE_URL = "/deleteSitePaymentService";
export const ADD_SITE_URL = "/updateSite";
export const LIST_ALL_APP_MENU = "/listAllApplicationMenu";
export const SAVE_APP_MENU_URL = "/SaveApplicationMenu";
export const DELETE_APP_MENU_URL = "/deleteApplicationMenu";
export const ORDER_APP_MENU_URL = "/orderApplicationMenu";
export const GET_SYSTEM_CONFIGURATION = "/getSystemConfiguration";
export const UPDATE_SYSTEM_CONFIGURATION = "/updateSystemConfiguration";
export const LIST_THIRD_PARTY_SERVICE = "/listThirdPartyServiceData";
export const UPDATE_THIRD_PARTY_SERVICE = "/updateThirdPartyServiceData";
export const LIST_BANNERS = "/listingHubBanners";
export const SAVE_BANNER = "/saveHubBanner";
export const DELETE_BANNER = "/deleteHubBanner";
export const LIST_COUNTDOWN_CONFIG = "/listCountdownConfiguration";
export const SAVE_COUNTDOWN_CONFIG = "/saveCountdownConfiguration";
export const DELETE_COUNTDOWN_CONFIG = "/deleteCountdownConfiguration";
export const SORT_COUNTDOWN_CONFIG = "/sortCountdownConfiguration";
export const GET_DEFAULT_COUNTDOWN = "/getDefaultCountdown";
export const SAVE_DEFAULT_COUNTDOWN = "/saveDefaultCountdown";
export const LIST_TUTORIALS = "/listAllTutorials";
export const SAVE_TUTORIAL = "/saveTutorial";
export const GET_TUTORIAL_BY_ID = "/getTutorialById";
export const DELETE_TUTORIAL = "/deleteTutorial";
export const LIST_ALL_FAQS = "/listAllFaq";
export const SAVE_FAQ = "/saveFaq";
export const DELETE_FAQ = "/deleteFaq";
export const SORT_FAQS = "/sortFaq";
export const GET_FAQ_BY_ID = "/getFaqDetails";
export const GET_EMAIL_DESIGN_TEMPLATEDETAIL = "/getEmailDesignTemplateDetail/";
export const LIST_EMAIL_DESIGN_TEMPLATE = "/listEmailDesignTemplate";
export const UPDATE_EMAIL_DESIGN_TEMPLATE = "/updateEmailDesignTemplate";
export const LIST_SYSTEMPAGE_AND_PAGELINK = "/listOfSystemPageAndPageLink";
export const LIST_EXPORT_COUNTDOWN_VIDEOLOGS = "/exportCountdownVideoLogs";
export const LIST_SMS_DESIGN = "/listSMSDesign";
export const GET_SMS_DESIGN_DETAIL = "/getSMSDesignDetail/";
export const UPDATE_SMS_DESIGN = "/updateSMSDesign";

export const GET_ICON_LINKS = "/getLinks";
export const LIST_HABIT_CATEGORY = "/listHabitCategory";
export const CREATE_HABIT_CATEGORY = "/createCategory";
export const DELETE_HABIT_CATEGORY = "/deleteHabitCategory";
export const EDIT_HABIT_CATEGORY = "/editHabitCategory";

// Profile
export const FETCH_USER_APPLICATION_URL = "/getSiteAccessDataByUserId";
export const GET_USER_ACTIVE_MEMBERSHIPS = "/getUserActiveMemberships";
export const FETCH_SUBSCRIPTION_DETAIL_URL = "/listSubscription";
export const LIST_USER_PAYMENT_METHODS = "/listUserPaymentMethod";
export const SAVE_USER_PAYMENT_METHODS = "/addOrUpdateCard";
export const DELETE_USER_PAYMENT_METHODS = "/deleteCard";
export const LIST_LOGIN_LOGS = "/listLoginLogs";
export const USER_ACTIVE_PRODUCT = "/userActiveProducts";
export const FETCH_EMAIL_LOGS_URL = "/listEmailLogs";
export const ADD_EDIT_ADDRESS = "/updateBillingShippingAddressDetail";
export const CHANGE_USER_STATUS = "/userAction";
export const FETCH_TRANSACTION_LOGS_URL = "/listTransaction";
export const FETCH_INVOICE_URL = "/getOrderDetails";
export const USER_SHARED_DASHBOARD_LIMIT = "/changeUserSharedDashboardLimit";
export const GET_USER_SHARED_MEMBERSHIP = "/getUserSharedMembership";
export const GET_USER_SHARED_MEMBERSHIP_OTHERS =
  "/getUserSharedMembershipOthers";
// Common
export const GET_STATES = "/listState";
export const GET_COUNTRIES = "/listCountry";
export const BULK_UPDATE_MEMBERSHIP_STATUS = "/bulkUpdateMembershipStatus";

// System Pages
export const LIST_SYSTEM_PAGES = "/listSystemPages";
export const UPDATE_SYSTEM_PAGE = "/updateSystemPages";
export const GET_SYSTEM_PAGE_DETAILS = "/detailSystemPages";
export const DELETE_SYSTEM_PAGES = "/deleteSystemPages";

//Game app
//Game
export const ADD_UPDATE_GAME = "/addUpdateGame";
export const DELETE_GAME_URL = "/deleteBulkGame";
export const LIST_GAME = "/listGame";
export const GAME_DETAIL = "/gameDetail";
export const LIST_FILTER = "/listFilter";
export const LIST_USER_GAME = "/listUserGame";
export const GET_ALL_ADMIN_GAMES = "/getAllAdminGames";
export const SEND_GAME_NOTIFICATION = "/sendPushNotification";
export const CREATE_SLIDESHOW_BY_GAME = "/createSlideshowByGame";
export const GET_SLIDESHOW_BY_GAME = "/getSlideshowsByGame";

//Filters
export const ADD_UPDATE_FILTER = "/addUpdateFilter";
export const LIST_ALL_FILTER_PAGINATION = "/listFilterData";
export const FILTER_DETAIL = "/filterDetail";
export const DELETE_FILTER = "/deleteBulkFilter";

//Icebreakers
export const LIST_ICEBREAKER = "/listIceBreaker";
export const LIST_FILTERS = "/listFilter";
export const ADD_UPDATE_ICEBREAKER = "/saveIceBreaker";
export const DELETE_ICEBREAKER_URL = "/bulkDeleteIceBreaker";
export const LIST_ICEBREAKER_BY_ID = "/listIceBreakerById";

// Permission Builder
export const GET_PERMISSION_PROFILES = "/listPermissionProfile";
export const GET_PERMISSION_PROFILE_DETAIL = "/PermissionProfileDetail";
export const GET_PERMISSIONS = "/getPermissions";
export const ADD_UPDATE_PERMISSION_PROFILE = "/savePermissionProfile";
export const REMOVE_PERMISSION_PROFILE = "/deletePermissionProfile";

//Builder
export const CREATE_MESSAGE = "/createMessageBuild";
export const CREATE_SERIES = "/createVolume";
export const LIST_MESSAGE_POST = "/listMessageBuild";
export const LIST_SERIES_POST = "/listSeriesBuild";
export const LIST_SERIES_SORT_POST = "/listPostSortOrders";
export const LIST_SORT_SERIES_POST = "/listSeriesSortOrders";
export const DELETE_MESSAGE = "/deleteMessageBuild";
export const DELETE_VOLUME = "/deleteVolume";
export const GET_SHARE_LINK = "/getShareLink";
export const GET_BUILDING_BLOCKS = "/listBuildElements";
export const GET_MB_ELEMENTS = "/listBuildElementsDetails";
export const DUPLICATE_ELEMENT = "/createDuplicateBuildElement";
export const UPDATE_BUILDER__ELEMENT_DETAILS = "/updateBuildelementsDetails";
export const UPDATE_MESSAGE_AND_ELEMENTS = "/updateMessageAndElements";
export const BUILDING_BLOCK_DRAG_DROP = "/addMessageElementForWeb";
export const BUILD_ELEMENT_DRAG_DROP = "/saveElementsSortOrder";
export const VALID_VIDEO_URL = "/validateVideoURL";
export const EXPORT_PDF = "/exportPdf";
export const GET_SERIES_ELEMENT_BY_SERIES_ID =
  "/getSeriesElementDetailsBySeriesId";
export const CREATE_DUPLICATE_SERIES = "/createDuplicateSeries";
export const UPDATE_SERIES = "/updateSeriesBuildList";
export const ADD_SERIES = "/addSeriesBuildList";
export const ADD_UPDATE_SERIES_ELEMENT = "/addUpdateSeriesElement";
export const SAVE_SERIES_SORT_ORDER = "/saveSeriesSortOrder";
export const UPDATE_VOLUME = "/createUpdateVolumeForMobile";
export const UPDATE_SERIES_TITLE = "/addUpdateSeriesBuildList";
export const UPDATE_SERIES_DESC = "/addUpdateSeriesForMobile";

export const CANCEL_SUBSCRIPTION_URL = "/cancelSubscription";
export const ONHOLD_SUBSCRIPTION_URL = "/updateSubscriptionStatus";
export const REACTIVE_SUBSCRIPTION_URL = "/reactiveSubscription";
export const REFUND_TRANSACTION_URL = "/refundTransaction";
export const MODIFY_SUBSCRIPTION_RENEWAL_URL = "/subscriptionRenewalStatus";
export const CHANGE_SUBSCRIPTION_STATUS_URL = "/changeSubscriptionStatus";
export const UPDATE_CHECK_STATUS_URL = "/updateCheckStatus";
// Review

export const LIST_GAME_RATING = "/listGameReview";
export const DELETE_GAME_REVIEW_URL = "/deleteGameReview";

// System Pages - Amazon Pages
export const ADD_UPDATE_AMAZON_EXTERNAL_PAGE = "/addUpdateAmazonExternalPage";
export const LIST_AMAZON_PAGES = "/amazonExternalPageList";
export const DELETE_AMAZON_PAGE = "/deleteAmazonExternalPage";

// Redirections

export const REDIRECTION_LIST_URL = "/redirectionLinkList";
export const ADD_UPDATE_REDIRECTION_URL = "/addUpdateRedirectionLink";
export const STATUS_REDIRECTION_LINK_URL = "/statusRedirectionLink";
export const DELETE_REDIRECTION_LINK_URL = "/deleteRedirectionLink";
export const REDIRECT_FOLDER_LIST_URL = "/listRedirectionFolder";
export const CREATE_UPDATE_REDIRECT_FOLDER_URL =
  "/createUpdateRedirectionFolder";
export const DELETE_REDIRECT_FOLDER_URL = "/deleteRedirectionFolder";
export const UPDATE_REDIRECTION_FOLDER = "/updateRedirectionFolderId";

// Slidr
export const GET_SLIDESHOW_BY_SERIES_ID = "/getSlideshowsBySeries";
export const GET_SLIDESHOW_PREVIEW = "/getSlideshowPreview";
export const CREATE_SLIDESHOW = "/createSlideshowBySeries";
export const GET_SLIDESHOW_DATA = "/getSlideShow";
export const UPDATE_SLIDESHOW_TITLE = "/updateSlideShowTitle";
export const SAVE_SLIDESHOW = "/saveSlideshow";
export const GET_NEW_SLIDE_ID = "/getSlideId";
export const DELETE_SLIDE = "/deleteSlide";
export const UPDATE_SLIDE_SHOW_SETTINGS = "/updateSlideShowSetting";
export const PUBLISH_SLIDE_SHOW = "/publishSlideshow";
export const DELETE_SLIDE_SHOW = "/deleteSlideShow";
export const GET_SYSTEM_SLIDESHOW_BY_SERIES = "/getSystemSlideshowBySeries";
export const IMPORT_SLIDESHOW = "/importSlideshow";

//Integrations

export const GET_THIRDPARTY_LIST = "/getThirdPartyList";
export const GET_ACTIVE_INACTIVE = "/activeInactiveThirdPartyAPI";
export const GET_THIRDPARTY_CONFIG_DETAILS =
  "/getThirdPartyConfigurationDetails";
export const SAVE_THIRDPARTY_CONFIG = "/saveThirdPartyConfigurationDetails";
export const LIST_CIRCLE_SPACES = "/listCircleSpaces";
export const LIST_THIRDPARTY_LOGS = "/listThirdPartyLogs";

export const LIST_MAP_PRODUCTS = "/listSycuMapProducts";
export const SAVE_SYCU_MAP_PRODUCTS = "/saveSycuMapProducts";
export const LIST_SHIPMENT_METHOD = "/listShipmentMethod";
export const LIST_SYCU_PRODUCTS = "/listSycuShippableProducts";
export const LIST_SHIPBOB_PRODUCTS = "/listShipbobProducts";
export const UPDATE_SHIPMENT_METHOD = "/updateShipmentMethod";
export const LIST_SHIPBOB_CHANNEL = "/listShipbobChannels";
export const SHIPBOB_CHANNEL_UPDATE = "/updateShipmentChannel";

export const GET_FILES_URL = "/apps/sycu/api/0.1/files";

//Affiliate
export const LIST_AFFILIATE = "/listAffiliate";
export const ADD_EDIT_AFFILIATE = "/addUpdateAffiliate";
export const AFFILIATE_CODE = "/affiliateCode";
export const DELETE_AFFILIATE = "/deleteAffiliate";
export const FETCH_RATE_CONFIGURATION_URL = "/listAffiliatesConfigurationsData";
export const SAVE_RATE_CONFIGURATION_URL =
  "/updateAffiliatesConfigurationsData";
export const AFFILIATE_SHARE_LINK = "/shareLink";

//Affiliate Dashboard
export const GET_LATEST_AFFILIATE_REGISTRATIONS =
  "/getLatestAffiliateRegistrations";
export const GET_All_AFFILIATE_TOTALS = "/getAllAffiliateTotals";
export const GET_MostValuable_Affiliates = "/getMostValuableAffiliates";
export const GET_Recent_Referrals = "/getRecentAffiliateReferrals";
export const GET_Conversion_Rate = "/getConversionRate";
export const GET_HIGHEST_CONVERTING_URLS = "/getHighestConvertingURLs";
export const GET_VISIT_REFERRAL_DATA_GRAPH = "/getVisitAndReferralDataForGraph";

//affiliate payout
export const GET_AFFILIATE_PAYOUT_LISTING = "/listAffiliatePayout";
export const GET_AFFILIATE_CALCULATE_PAYOUT = "/calculatePayout";
export const SAVE_AFFILIATE_PAYNOW_URL = "/payAffiliate";
export const DELETE_AFFILIATE_PAYOUT = "/deleteAffiliatePayout";

//Visit
export const LIST_AFFILIATE_VISIT_DATA_URL = "/listAffiliatesVisitData";

//Affiliate referral
export const USER_LIST_AFFILIATE_REFERRAL = "/userListAffiliateReferral";
export const FETCH_AFFILIATE_REFERRAL_URL = "/listAffiliateReferral";
export const DELETE_AFFILIATE_REFERRAL = "/deleteAffiliateReferral";
export const SAVE_AFFILIATE_REFERRAL_URL = "/saveAffiliateReferral";
export const MARK_AS_PAID_AFFILIATE_REFERRAL = "/referralPayout";
export const GET_AFFILIATE_MODAL_VIEW = "/getAffiliateDetails";

//Debugger tool
export const LIST_API_LOGS = "/listApiLogs";
export const SITES_LISTS = "/sitesList";
export const DEBUGGER_ACTIVE_INACTIVE = "/debuggerIsActiveOrNot";
export const REGISTER_CLOUD_LOG = "/saveCloudStorageLogs";
export const CLEAR_API_LOG_DATA = "/clearApiLogData";
export const DELETE_ERRORLOG_FILES = "deleteErrorLogFiles";

//Report

export const LIST_REVENUE_REPORT_DATA_URL = "/getRevenueReportData";
export const LIST_PRODUCT_REVENUE_REPORT_DATA_URL =
  "/getRevenueByProductReportData";
export const GET_RENEWALS_REPORTS_DATA = "/getRenewalsReportData";
export const GET_REGISTERED_USERS = "/getRegisterdUserReportData";
export const GET_CANCELLED_SUBSCRIPTION_GRAPH_DATA =
  "/canceledSubscriptionGraph";
export const LIST_SIGNUP_METRIC_REPORT = "/listsignupMetricReport";
export const LIST_SIGNUP_METRIC_GRAPH_REPORT = "/listsignupGraphMetricReport";

// Logs
export const GET_ADMIN_ACTIVITY_LOGS = "/getAdminActivityLog";

// email logs
export const GET_EMAIL_LOG_DATA = "/getEmailLogData";

// Analytics -------

//Renewal
export const GET_RENEWALS_REPORTS_MONTH_DATA = "/getMonthWiseSubscriptionCount";
export const GET_RENEWALS_TABLE_MONTH_DATA =
  "/getMonthWiseSubscriptionTableData";
export const GET_RENEWAL_DATA_DATE_WISE = "/getRenewSubscriptionDataDateWise";

// Revenue
export const GET_REVENUE_REPORTS_DATA = "/getRevenueCount";
export const GET_REVENUE_GRAPH_DATA = "/getRevenueGrossSaleGraphData";
export const GET_REVENUE_Table_DATA = "/getRevenueListDateWise";

//Order

export const GET_ORDER_REPORTS_DATA = "/getOrderCount";
export const GET_ORDER_GRAPH_DATA = "/getOrderGraphData";
export const GET_ORDER_TABLE_DATA = "/getOrderListDateWise";

// Coupons
export const GET_COUPONS_REPORTS_DATA = "/getCouponUsedCount";
export const GET_COUPONS_GRAPH_DATA = "/getCouponUsedChartData";
export const GET_COUPONS_Table_DATA = "/getCouponUserListData";

// Product start
export const GET_PRODUCTS_REPORTS_DATA = "/getProductSoldCounts";
export const GET_PRODUCT_SOLD_LIST_URL = "/getProductSoldList";

export const GET_ANALYTICS_PRODUCT_GRAPH_URL = "/getProductSoldChartData";
export const LIST_ORDER_BY_DATE = "/getOrderByDate";

// Product end

//Analytics Users
export const LIST_ANALYTICS_USERS_COUNTS = "/getUserCounts";
export const LIST_ANALYTICS_USERS_GRAPH_DATA = "/getFreeUserGraphData";
export const LIST_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA =
  "/getSubscribeUserGraphData";
export const LIST_ANALYTICS_USERS_DATA = "/getUserListDateWise";
export const LIST_ANALYTICS_REGISTER_USERS_GRAPH_DATA =
  "/getRegisteredUserGraphData";

//subscription

export const LIST_SUBSCRIPTION_REPORT_DATA_URL = "/getSubscriptionCount";
export const LIST_SUBSCRIPTION_REPORT_DATE_WISE =
  "/getSubscriptionDataDateWise";
export const LIST_NEW_SUBSCRIPTION_CHART = "/getNewSubscriptionChartDatas";
export const LIST_RENEWED_SUBSCRIPTION_CHART =
  "/getRenewedSubscriptionChartDatas";
export const GET_PAYMENT_TOKEN = "/getPaymentToken";
export const GET_SHIPBOB_ORDERS = "/getShipbobOrders";
export const GET_SHIPMENT_DETAILS = "/getShipmentTimeline";
export const CREATE_MANUAL_SHIBOB_ORDER = "/createManualShipBobOrder";
export const GET_USER_MEMBERSHIP_DETAILS = "/getUserMembershipDetails";
export const GET_FAIL_SUBSCRIPTION = "/failedSubscription";
export const GET_NOT_ACTIVE_SUBSCRIPTION = "/listNotActiveSubscriptionReport";
export const SUBSCRIPTION_FEEDBACK_REPORT = "/subscriptionFeedbackReport";

// Success Subscription
export const LIST_SUCCESS_SUBSCRIPTION = "/successSubscription";

// Active Subscription
export const LIST_ACTIVE_SUBSCRIPTION = "/activeSubscription";

// Expired Subscription
export const LIST_EXPIRED_SUBSCRIPTION = "/expireSubscription";

//Disputed Transactions
export const GET_DISPUTED_TRANSACTIONS = "/getDisputedTransaction";
export const VERIFY_EVIDENCE_DATA = "/submitDisputeEvidence";

//Emails
export const LIST_ANALYTICS_EMAIL_DATA = "/getEmailListData";
export const LIST_ANALYTICS_EMAIL_DATA_BY_DATE_AND_SUBJECT =
  "/getEmailListDataByDateAndSubject";

export const LIST_EMAIL_GRAPH_DATA_URL = "/getEmailGraphData";

// Analytics Dashboard
export const OVERALL_PERFORMANCE = "/overallPerformance";
export const OVERALL_SUBSCRIPTIONS = "/overallSubscription";
export const SAVE_ANALYTICS_DASHBOARD_SETTINGS =
  "/saveUserAnalyticsConfigurationData";
export const GET_ANALYTICS_DASHBOARD_SETTINGS =
  "/getUserAnalyticsConfigurationData";

/// realtime

export const FETCH_BROWSERS_CARD_DATA = "/browsers";
export const FETCH_PAGE_DATA = "/pageviews";
export const GET_PAGES_DATA = "/pages?q=";
export const FETCH_OPERATING_CARD_DATA = "/os";
export const FETCH_DEVICES_DATA = "/devices";
export const FETCH_LOCATIONS_DATA = "/locations";
export const FETCH_SESSIONS_DATA = "/Sessions";
export const FETCH_BROWSER_DATA_ = "/LocationsDetail";

// affiliate dashboard

export const FETCH_USER_GRAPH_DATA = "/getUsersGraphData";
// export const FETCH_USER_DEVICE_DATA = "/devices";
// export const FETCH_ONLINE_DEVICE_DATA = "/devices";
// export const FETCH_USER_LOCATION_DATA = "/locations";
export const FETCH_UNIQUE_REMOTE_IP_DATA = "/uniquevisits";
export const FETCH_PAGE_VIEW_TABLE_DATA = "/pageviews";
export const FETCH_TOTAL_VISIT_DATE_WISE_DATA = "/totalvisits";
export const FETCH_AVERAGE_PER_SESSION_DATA = "/avgpersession";
export const FETCH_AVERAGE_PER_PAGE_SESSION_DATA = "/avgperpagesession";

//security question and answer
export const SAVE_SECURITY_QUESTION_ANSWER = "/saveSecurityQuestionAnswer";
export const GET_SECURITY_QUESTION_ANSWER = "/getSecurityQuestion";

//Habit app
//Game
export const ADD_HABIT_URL = "/createHabit";
export const EDIT_HABIT_URL = "/editHabit";
export const DELETE_HABIT_URL = "/deleteHabit";
export const LIST_HABIT = "/listHabit";
export const LIST_USER_HABIT = "/listUserGame";
export const SEND_HABIT_NOTIFICATION = "/sendPushNotification";
export const LIST_CATEGORY = "/listHabitCategory";
export const LIST_ICON = "/getLinks";
export const HABIT_DETAIL = "/fetchHabitById";

/////cloud

export const SAVE_CLOUD_DIR_DATA = "/saveResource";
export const FETCH_CLOUD_DIR_DATA = "/listAllResource";
export const DELETE_FILES_DATA = "/deleteResource";
export const SHARE_FOLDER_LINK = "/createResourceLink";
export const ACTIVITY_LOG_DATA = "/getAdminActivityLog";
export const SYNC_FILE = "/admin/syncFile";
export const DELETE_FILE_FROM_SERVER = "/deleteFile";

// subscription report
export const LIST_SUBSCRIPTION_REPORT = "/listSubscriptionReport";
export const USER_AC_STATUS_UPDATE = "/userACStatusUpdate";

export const TRANSFER_SUBSCRIPTION = "/transferSubscription";
export const UPDATE_EMAIL_OR_USERNAME = "/updateEmailOrUsername";

/// digital board
export const GET_DIGITAL_BOARD_DATA = "/listAdminBoard";
export const DELETE_DIGITAL_BOARD = "/deleteBoard";
export const DUPLICATE_DIGITAL_BOARD = "/duplicateBoard";
export const EXPORT_DIGITAL_BOARD = "/exportPreview";
export const EDIT_BOARD_DETAILS = "/EditBoardDetail";
export const CREATE_ROW = "/createBoardRow";
export const UPDATE_CARD = "/updateBoardCard";
export const MERGE_CARDS = "/mergeBoardCard";
export const SEPARATE_CARD = "/separateCard";
export const UPDATE_ROWS = "/updateRows";
export const CREATE_BOARD = "/createBoard";
export const DELETE_BOARD = "/deleteBoard";
export const EDIT_BOARD_GRID = "/editBoardGrid";
export const GET_VOLUME_BOARD = "/getVolumeBoard";

export const CREATE_AND_MERGE_CARD = "/createAndMergeCard";
export const UPDATE_CARD_COORDINATES_INDEX = "/updateCardCoordinate";
export const EDIT_BOARD_ZOOM_LEVEL = "/editBoardZoomLevel";
export const UPDATE_CANVAS_CARD_COORDINATES = "/updateCanvasCardCoordinate";
export const EDIT_BOARD_NAME = "/editBoardName";
export const CREATE_UPDATE_CARD = "/createUpdateBoardCard";
export const PUBLISH_BOARD = "/publishAdminTemplate";
export const PREVIEW_BOARD_DATA = "/previewBoardData";
export const LOCK_CANVAS_CARD = "/lockUnlockCard";
export const EDIT_BOARD_BACKGROUND = "/editBoardBackground";
export const LIST_BOARD_BACKGROUND_LIBRARY = "/listBackgroundLibrary";
export const ADD_BOARD_BACKGROUND_LIBRARY = "/addBackgroundLibrary";
export const DELETE_BOARD_BACKGROUND_LIBRARY = "/deleteBackgroundLibrary";
export const DELETE_DIGITAL_BOARD_CARD = "/deleteBoardCard";
export const IMPORT_BOARD_DATA = "/importBoardData";

export const SEND_INVITATION = "/sendBoardInvitation";
export const VERIFY_BOARD_TOKEN = "/verifyBoardToken";
export const ACCEPT_REJECT_INVITATION = "/acceptRejectInvitation";
export const RESEND_INVITATION = "/resendInvitation";
export const COPY_INVITATION_LINK = "/copyLink";

export const TOGGLE_PERMISSION = "/changeBoardAccessPermission";
export const DELETE_INVITED_USER = "/deleteInvitedUser";
export const LINK_SHARING = "/linkSharing";

//admin activity log
export const USER_WISE_ADMIN_ACTIVITY_LOG = "/userWiseAdminActivityLog";

export const SORT_FREE_VBS_PAGES_API = "/sortFreeVbsPages";

///LIVE CHAT ARCHIVE
export const LIVE_CHAT_ARCHIVE_DATA = "/listLiveChatData";

//GEO Location
export const USER_lOCATION_DATA = "/listGeoData";
export const LIST_CONFIG_DATA = "/listGeoColor";
export const SAVE_CONFIG_DATA = "/saveGeoConfig";

///Curriculum Volume Vuilder

export const GET_SERIES_RESOURCES_DETAILS_API = "/getSeriesResourcesDetails";
export const UPDATE_SERIES_RESOURCES_DETAILS_API =
  "/updateSeriesResourcesDetails";

export const SORT_POSTS_CURR_FOLDERS = "/sortPostFolders";
export const UPDATE_PAGE_TITLE = "/updatePageTitle";

//Intake form

export const LIST_INTAKE_FORM_URL = "/getIntakeFormList";
export const SAVE_INTAKE_FORM_DATA_URL = "/saveIntakeApplication";
export const SAVE_INTAKE_FORM_STATUS = "/saveIntakeFormStatus";

export const SIGNUP_CAMPAIGN_REPORT = "/signupCampaignReport";

export const FEEDBACK_OPTION = "/FeedbackOptionData";
export const ADD_FEEDBACK_OPTION = "/addFeedbackOptionData";
export const EDIT_FEEDBACK_OPTION = "/editFeedbackOptionData";
export const DELETE_FEEDBACK_OPTION = "/deleteFeedbackOptionData";

export const UPDATE_ORDER_DETAILS = "/updateOrderDetails";
export const ALLOW_CIRCLE_ACCESS = "/allowCircleAccess";

export const ADD_DASHBOARD_WIDGET = "/dashbordwidgetCreate";
export const DELETE_DASHBOARD_WIDGET = "/dashbordwidgetDelete";
export const LIST_DASHBOARD_WIDGET = "/dashbordwidgetList";

//sort kids music
export const SAVE_POST_SORT_ORDER = "/savePostSortOrder";

//GrowCon Report
export const LIST_CON_USER = "/listConUserReport";
export const UPDATE_CON_USER_DETAILS = "/updateGrowConUserDetail";

export const GET_BLOG_SITE_MAP_DATA = "/blogSiteMapReport";
export const GET_BLOG_SITEMAP_CHART = "/blogSiteMapChart";

//GrowCon Video for slidr
export const LIST_GROW_CON_VIDEO = "/getAllGrowConVideoes";
export const ADD_GROW_CON_VIDEOS = "/addEditGrowConVideoes";
export const REMOVE_GROW_CON_VIDEOS = "/removeGrowConVideoes";
export const GET_ALL_GROW_CON_FOLDER = "/getAllGrowConFolders";
export const ADD_EDIT_GROW_CON_FOLDER = "/addEditGrowConFolder";
export const DELETE_GRO_CON_FOLDER = "/deleteGrowConFolder";

// New Active Subscription
export const NEW_LIST_ACTIVE_SUBSCRIPTION = "/newSubscription";
export const NEW_LIST_ON_BOARDING = "/getUserOnboardingReport";

export const ALL_ACTIVE_SUBSCRIPTIONS = "/allActiveSubscriptions";
export const APPLY_SCHOLARSHIP_REFUND_CHECK = "/applyScholarshipRefundCheck";

// Grow Stories Free-Trial

export const GROW_FREE_TRIAL_STORY_LIST = "/listAllGrowStories";
export const GROW_FREE_TRIAL_STEWARD_LIST = "/listAllApplicationAds";
export const GROW_FREE_TRIAL_HELPFUL_LIST = "/listAllHelpfulResources";
export const GROW_FREE_TRIAL_APPLICATON_ADS_COLOR_LIST =
  "/listAllApplicationColor";
export const GROW_FREE_TRIAL_TO_DO_LIST = "/listAllTodoList";
export const SORT_GROW_STORY = "/sortGrowStories";
export const SORT_GROW_TODO = "/sortToDoList";
export const SORT_GROW_HELPFUL = "/sortHelpfulResources";
export const SORT_STEWARD_YOUR_MINISTRY = "/sortApplicationAds";
export const GROW_FREE_TRIAL_SAVE_STEWARD = "/saveApplicationAds";
export const GROW_FREE_TRIAL_APPLICATION_ADS_SAVE_COLOR =
  "/saveApplicationColor";
export const SAVE_FREE_TRIAL_HELFUL_RESOURCES = "/saveHelpfulResource";
export const GROW_FREE_TRIAL_CREATE_STORY = "/saveGrowStory";
export const GROW_FREE_TRIAL_STORY_DELETE = "/deleteGrowStory";
export const GROW_FREE_TRIAL_TODO_DELETE = "/deleteToDoList";
export const GROW_FREE_TRIAL_STEWARD_DELETE = "/deleteApplicationAds";
export const GROW_FREE_TRIAL_HELPFUL_DELETE = "/deleteHelpfulResource";
export const GROW_SAVE_TO_DO_LIST = "/saveToDoList";

//getCalenderCardReport
export const LIST_CHURCH = "/listAllChurch";

// CANCEL_SUBSCRIPTION_REPORT
export const CANCEL_SUBSCRIPTION_REPORT = "/cancelSubscriptionReport";

//CHEKOUT_CAMPAIGN_REPORT
export const CHEKOUT_CAMPAIGN_REPORT = "/checkoutCampaignReport";

//getCalenderCardReport
export const GET_CALENDER_CARD_REPORT = "/getCalenderCardReport";
export const MODAL_FEEDBACK_REPORT_LIST = "/getFeedbackReport";

//grow books

export const Get_GROW_BOOK_CATEGORY = "/listAllGrowBooksCategories";
export const GROW_CREATE_BOOK_CATEGORY = "/saveGrowBooksCategory";
export const GROW_BOOK_CATEGORY_DELETE = "/deleteGrowBookCategory";

export const GET_GROW_BOOK_AUTHORS = "/listAllGrowBooksAuthors";
export const SAVE_GROW_BOOK_AUTHOR = "/saveGrowBooksAuthor";
export const DELETE_GROW_BOOK_AUTHOR = "/deleteGrowBookAuthor";
export const UPDATE_GROW_BOOK_AUTHOR_STATUS = "/updateGrowBookAuthorStatus";

export const LIST_ALL_BOOKS = "/listAllGrowBooks";
export const GROW_BOOKS_DELETE = "/deleteGrowBook";
export const SAVE_GROW_BOOKS_URL = "/saveGrowBook";
export const LIST_AUTHOR_NAME_URL = "/getGrowBookAuthors";
export const LIST_BOOK_CATEGORY_URL = "/getGrowBookCategory";

//Support Ticket
export const LIST_SUPPORT_TICKETS = "/listSupportTickets";
export const LIST_ASSIGNED_OPEN_SUPPORT_TICKETS = "/listAssignedOpenSupportTickets";
export const LIST_ASSIGNED_CLOSEED_SUPPORT_TICKETS = "/listAssignedClosedSupportTickets";
export const LIST_UNASSIGNED_SUPPORT_TICKETS = "/listUnassignedSupportTickets";
export const GET_SUPPORT_TICKET = "/getSupportTicket";
export const LIST_SUPPORT_TICKET_NOTES = "/listSupportTicketNotes";
export const LIST_SUPPORT_TICKET_ACTIVITIES = "/listSupportTicketActivities";
export const CREATE_SUPPORT_TICKET_NOTE = "/createSupportTicketNote";


export const LIST_SUPPORT_TICKET_TYPES = "/listSupportTicketTypes";
export const LIST_SUPPORT_TICKET_SERVICES = "/listSupportTicketServices";
export const LIST_SUPPORT_TICKET_STATUS = "/listSupportTicketStatus";
export const LIST_SUPPORT_TICKET_APPLICATIONS = "/listSupportTicketApplications";
export const LIST_FREE_TRIAL_REPORT = "/freeTrialBuyUsersReportData";


// Recommended Font Style
export const RECOMMENDED_FONTS = "/recommendedFonts";

//
export const LIST_SITE_SERIES_EDITOR = "/listSiteForSeriesEditor";
export const LIST_SITE_BY_SITE_ID = "/listSiteBySiteId";

//Api Sort Order
export const SAVE_SORT_ORDER_ANNOUNCEMENT = "/saveSortOrderOfAnnouncements" 
export const SAVE_SORT_ORDER_CALENDAR = "/saveSortOrderOfCalendars" 

export const NINE_DOTS = "/getDashboardHeaderNineDotsV2";

//Facebook Groups

export const FETCH_FACEBOOK_GROUPS = "/facebook-groups";
export const ADD_EDIT_FACEBOOK_GROUP = "/facebook-group";
export const FACEBOOK_GROUP_ACTIVITY = "/facebook-group-activities";
