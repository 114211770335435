export const SET_SITE_ID = "SITE_ID";

export const GET_SERVICE = "GET_SERVICE";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_ERROR = "GET_SERVICE_ERROR";
export const CLEAR_SERVICE = "CLEAR_SERVICE";

export const ADD_EDIT_SERVICE = "ADD_EDIT_SERVICE";
export const ADD_EDIT_SERVICE_SUCCESS = "ADD_EDIT_SERVICE_SUCCESS";
export const ADD_EDIT_SERVICE_ERROR = "ADD_EDIT_SERVICE_ERROR";

export const DELETE_EMAIL_SERVICE = "DELETE_EMAIL_SERVICE";
export const DELETE_EMAIL_SERVICE_SUCCESS = "DELETE_EMAIL_SERVICE_SUCCESS";
export const DELETE_EMAIL_SERVICE_ERROR = "DELETE_EMAIL_SERVICE_ERROR";

export const FETCH_PAYMENT_SERVICE = "FETCH_PAYMENT_SERVICE";
export const FETCH_PAYMENT_SERVICES_SUCCESS = "FETCH_PAYMENT_SERVICES_SUCCESS";
export const FETCH_PAYMENT_SERVICE_ERROR = "FETCH_PAYMENT_SERVICE_ERROR";

//Nandini changes 10/3/2023
export const GET_DASHBOARD_PAGE_CONTACT = "GET_DASHBOARD_PAGE_CONTACT";
export const GET_DASHBOARD_PAGE_CONTACT_SUCCESS =
  "GET_DASHBOARD_PAGE_CONTACT_SUCCESS";
export const GET_DASHBOARD_PAGE_CONTACT_ERROR =
  "GET_DASHBOARD_PAGE_CONTACT_ERROR";
export const SAVE_HOME_DASHBOARD_DETAILS = "SAVE_HOME_DASHBOARD_DETAILS";
export const SAVE_HOME_DASHBOARD_DETAILS_SUCCESS =
  "SAVE_HOME_DASHBOARD_DETAILS_SUCCESS";
export const SAVE_HOME_DASHBOARD_DETAILS_ERROR =
  "SAVE_HOME_DASHBOARD_DETAILS_ERROR";

export const SAVE_PAYMENT_SERVICE = "SAVE_PAYMENT_SERVICE";
export const SAVE_PAYMENT_SERVICE_SUCCESS = "SAVE_PAYMENT_SERVICE_SUCCESS";
export const SAVE_PAYMENT_SERVICE_ERROR = "SAVE_PAYMENT_SERVICE_ERROR";

export const FETCH_SERVER_CREDENTIAL = "FETCH_SERVER_CREDENTIAL";
export const FETCH_SERVER_CREDENTIAL_SUCCESS =
  "FETCH_SERVER_CREDENTIAL_SUCCESS";
export const FETCH_SERVER_CREDENTIAL_ERROR = "FETCH_SERVER_CREDENTIAL_ERROR";

export const DELETE_SITE_PAYMENT_SERVICE = "DELETE_SITE_PAYMENT_SERVICE";
export const DELETE_SITE_PAYMENT_SERVICE_SUCCESS =
  "DELETE_SITE_PAYMENT_SERVICE_SUCCESS";
export const DELETE_SITE_PAYMENT_SERVICE_ERROR =
  "DELETE_SITE_PAYMENT_SERVICE_ERROR";

export const ADD_EDIT_SITE = "ADD_EDIT_SITE";
export const ADD_EDIT_SITE_SUCCESS = "ADD_EDIT_SITE_SUCCESS";
export const ADD_EDIT_SITE_ERROR = "ADD_EDIT_SITE_ERROR";

export const FETCH_SITES = "FETCH_SITES";
export const FETCH_SITES_SUCCESS = "FETCH_SITES_SUCCESS";

/*25-02-22-*/
export const GET_SMS_SERVICE = "GET_SMS_SERVICE";
export const GET_SMS_SERVICE_SUCCESS = "GET_SMS_SERVICE_SUCCESS";
export const GET_SMS_SERVICE_ERROR = "GET_SMS_SERVICE_ERROR";

/** App menu list */
export const GET_ALL_APP_MENU = "GET_ALL_APP_MENU";
export const GET_ALL_APP_MENU_SUCCESS = "GET_ALL_APP_MENU_SUCCESS";
export const GET_ALL_APP_MENU_ERROR = "GET_ALL_APP_MENU_ERROR";

export const SAVE_APP_MENU = "SAVE_APP_MENU";
export const SAVE_APP_MENU_SUCCESS = "SAVE_APP_MENU_SUCCESS";
export const SAVE_APP_MENU_ERROR = "SAVE_APP_MENU_ERROR";

export const DELETE_APP_MENU = "DELETE_APP_MENU";
export const DELETE_APP_MENU_SUCCESS = "DELETE_APP_MENU_SUCCESS";
export const DELETE_APP_MENU_ERROR = "DELETE_APP_MENU_ERROR";

export const ORDER_APP_MENU = "ORDER_APP_MENU";
export const ORDER_APP_MENU_SUCCESS = "ORDER_APP_MENU_SUCCESS";
export const ORDER_APP_MENU_ERROR = "ORDER_APP_MENU_ERROR";

export const FETCH_SMS_SERVER_CREDENTIAL = "FETCH_SMS_SERVER_CREDENTIAL";
export const FETCH_SMS_SERVER_CREDENTIAL_SUCCESS =
  "FETCH_SMS_SERVER_CREDENTIAL_SUCCESS";
export const FETCH_SMS_SERVER_CREDENTIAL_ERROR =
  "FETCH_SMS_SERVER_CREDENTIAL_ERROR";

export const ADD_EDIT_SMS_SERVICE = "ADD_EDIT_SMS_SERVICE";
export const ADD_EDIT_SMS_SERVICE_SUCCESS = "ADD_EDIT_SMS_SERVICE_SUCCESS";
export const ADD_EDIT_SMS_SERVICE_ERROR = "ADD_EDIT_SMS_SERVICE_ERROR";

export const CLEAR_MENU_DATA = "CLEAR_MENU_DATA";

//policy
export const GET_SYSTEM_CONFIG = "GET_SYSTEM_CONFIG";
export const GET_SYSTEM_CONFIG_SUCCESS = "GET_SYSTEM_CONFIG_SUCCESS";
export const GET_SYSTEM_CONFIG_ERROR = "GET_SYSTEM_CONFIG_ERROR";

export const SAVE_SYSTEM_CONFIG = "SAVE_SYSTEM_CONFIG";
export const SAVE_SYSTEM_CONFIG_SUCCESS = "SAVE_SYSTEM_CONFIG_SUCCESS";
export const SAVE_SYSTEM_CONFIG_ERROR = "SAVE_SYSTEM_CONFIG_ERROR";

export const GET_ACTIVE_TYPE = "GET_ACTIVE_TYPE";

export const SYSTEM_PAGE_PAGELINK = "SYSTEM_PAGE_PAGELINK";
export const SYSTEM_PAGE_PAGELINK_SUCCESS = "SYSTEM_PAGE_PAGELINK_SUCCESS";
export const SYSTEM_PAGE_PAGELINK_ERROR = "SYSTEM_PAGE_PAGELINK_ERROR";

//Affiliate
export const GET_AFFILIATE = "GET_AFFILIATE";
export const GET_AFFILIATE_SUCCESS = "GET_AFFILIATE_SUCCESS";
export const GET_AFFILIATE_ERROR = "GET_AFFILIATE_ERROR";

export const FETCH_RATE_CONFIGURATION = "FETCH_RATE_CONFIGURATION";
export const FETCH_RATE_CONFIGURATION_SUCCESS =
  "FETCH_RATE_CONFIGURATION_SUCCESS";
export const FETCH_RATE_CONFIGURATION_ERROR = "FETCH_RATE_CONFIGURATION_ERROR";

export const SAVE_RATE_CONFIGURATION = "SAVE_RATE_CONFIGURATION";
export const SAVE_RATE_CONFIGURATION_SUCCESS =
  "SAVE_RATE_CONFIGURATION_SUCCESS";
export const SAVE_RATE_CONFIGURATION_ERROR = "SAVE_RATE_CONFIGURATION_ERROR";
export const FILTER_TYPE_AFFILIATE = "FILTER_TYPE_AFFILIATE";
// Table
export const SET_AFFILIATE_PAGE = "SET_AFFILIATE_PAGE";
export const SET_AFFILIATE_SEARCH = "SET_AFFILIATE_SEARCH";
export const SET_AFFILIATE_SIZE_PER_PAGE = "SET_AFFILIATE_SIZE_PER_PAGE";
export const SET_AFFILIATE_SORT_FIELD = "SET_AFFILIATE_SORT_FIELD";
export const SET_AFFILIATE_SORT_ORDER = "SET_AFFILIATE_SORT_ORDER";
export const CLEAR_AFFILIATE = "CLEAR_AFFILIATE";
export const SET_AFFILIATE_FILTER = "SET_AFFILIATE_FILTER";
export const SET_AFFILIATE_DATE_RANGE = "SET_AFFILIATE_DATE_RANGE";
export const SET_AFFILIATE_LOADER = "SET_AFFILIATE_LOADER";
export const SET_AFFILIATE_REFFERAL_LOADER = "SET_AFFILIATE_REFFERAL_LOADER";
export const SET_AFFILIATE_PAYNOW_LOADER = "SET_AFFILIATE_PAYNOW_LOADER";
export const SET_AFFILIATE_CALCULATE_LOADER = "SET_AFFILIATE_CALCULATE_LOADER";

//Affiliate Dashboard
export const FETCH_LATEST_AFFILIATE_USERS = "FETCH_LATEST_AFFILIATE_USERS";
export const FETCH_LATEST_AFFILIATE_USERS_SUCCESS =
  "FETCH_LATEST_AFFILIATE_USERS_SUCCESS";
export const FETCH_LATEST_AFFILIATE_USERS_ERROR =
  "FETCH_LATEST_AFFILIATE_USERS_ERROR";
//Total
export const FETCH_HIGHEST_CONVERTING_URLS = "FETCH_HIGHEST_CONVERTING_URLS";
export const FETCH_HIGHEST_CONVERTING_URLS_SUCCESS =
  "FETCH_HIGHEST_CONVERTING_URLS_SUCCESS";
export const FETCH_HIGHEST_CONVERTING_URLS_ERROR =
  "FETCH_HIGHEST_CONVERTING_URLS_ERROR";
//Most VALUABLE affiliates
export const FETCH_MOST_VALUABLE_AFFILIATES = "FETCH_MOST_VALUABLE_AFFILIATES";
export const FETCH_MOST_VALUABLE_AFFILIATES_SUCCESS =
  "FETCH_MOST_VALUABLE_AFFILIATES_SUCCESS";
export const FETCH_MOST_VALUABLE_AFFILIATES_ERROR =
  "FETCH_MOST_VALUABLE_AFFILIATES_ERROR";
//Recent referrals
export const FETCH_RECENT_REFERRALS = "FETCH_RECENT_REFERRALS";
export const FETCH_RECENT_REFERRALS_SUCCESS = "FETCH_RECENT_REFERRALS_SUCCESS";
export const FETCH_RECENT_REFERRALS_ERROR = "FETCH_RECENT_REFERRALS_ERROR";
//Recent referrals
export const FETCH_AFFILIATE_PAYOUT = "FETCH_AFFILIATE_PAYOUT";
export const FETCH_AFFILIATE_PAYOUT_SUCCESS = "FETCH_AFFILIATE_PAYOUT_SUCCESS";
export const FETCH_AFFILIATE_PAYOUT_ERROR = "FETCH_AFFILIATE_PAYOUT_ERROR";
export const SET_AFFILIATE_PAYOUT_PAGE = "SET_AFFILIATE_PAYOUT_PAGE";
export const SET_AFFILIATE_PAYOUT_SEARCH = "SET_AFFILIATE_PAYOUT_SEARCH";
export const SET_AFFILIATE_PAYOUT_SIZE_PER_PAGE =
  "SET_AFFILIATE_PAYOUT_SIZE_PER_PAGE";
export const SET_AFFILIATE_PAYOUT_SORT_FIELD =
  "SET_AFFILIATE_PAYOUT_SORT_FIELD";
export const SET_AFFILIATE_PAYOUT_SORT_ORDER =
  "SET_AFFILIATE_PAYOUT_SORT_ORDER";
export const CLEAR_AFFILIATE_PAYOUT = "CLEAR_AFFILIATE_PAYOUT";
export const SET_AFFILIATE_PAYOUT_PAYMENT_METHOD =
  "SET_AFFILIATE_PAYOUT_PAYMENT_METHOD";
export const SET_AFFILIATE_PAYOUT_STATUS = "SET_AFFILIATE_PAYOUT_STATUS";
export const SET_AFFILIATE_PAYOUT_START_DATE =
  "SET_AFFILIATE_PAYOUT_START_DATE";
export const SET_AFFILIATE_PAYOUT_END_DATE = "SET_AFFILIATE_PAYOUT_END_DATE";

//Affiliate Visit
export const LIST_AFFILIATE_VISIT_DATA = "LIST_AFFILIATE_VISIT_DATA";
export const LIST_AFFILIATE_VISIT_DATA_SUCCESS =
  "LIST_AFFILIATE_VISIT_DATA_SUCCESS";
export const LIST_AFFILIATE_VISIT_DATA_ERROR =
  "LIST_AFFILIATE_VISIT_DATA_ERROR";

export const SET_AFFILIATE_VISIT_PAGE = "SET_AFFILIATE_VISIT_PAGE";
export const SET_AFFILIATE_VISIT_SEARCH = "SET_AFFILIATE_VISIT_SEARCH";
export const SET_AFFILIATE_VISIT_SIZE_PER_PAGE =
  "SET_AFFILIATE_VISIT_SIZE_PER_PAGE";
export const SET_AFFILIATE_VISIT_SORT_FIELD = "SET_AFFILIATE_VISIT_SORT_FIELD";
export const SET_AFFILIATE_VISIT_SORT_ORDER = "SET_AFFILIATE_VISIT_SORT_ORDER";
export const CLEAR_AFFILIATE_VISIT = "CLEAR_AFFILIATE_VISIT";

//Affiliate Referral
export const FETCH_AFFILIATE_REFERRAL = "FETCH_AFFILIATE_REFERRAL";
export const FETCH_AFFILIATE_REFERRAL_SUCCESS =
  "FETCH_AFFILIATE_REFERRAL_SUCCESS";
export const FETCH_AFFILIATE_REFERRAL_ERROR = "FETCH_AFFILIATE_REFERRAL_ERROR";
export const SET_REFERRAL_PAGE = "SET_REFERRAL_PAGE";
export const SET_REFERRAL_SEARCH = "SET_REFERRAL_SEARCH";
export const SET_REFERRAL_SIZE_PER_PAGE = "SET_REFERRAL_SIZE_PER_PAGE";
export const SET_REFERRAL_SORT_FIELD = "SET_REFERRAL_SORT_FIELD";
export const SET_REFERRAL_SORT_ORDER = "SET_REFERRAL_SORT_ORDER";
export const SET_REFERRAL_TYPE = "SET_REFERRAL_TYPE";
export const SET_REFERRAL_STATUS = "SET_REFERRAL_STATUS";
export const CLEAR_AFFILIATE_REFERRAL = "CLEAR_AFFILIATE_REFERRAL";

///affiliate modal
export const FETCH_AFFILIATE_MODAL_DATA = "FETCH_AFFILIATE_MODAL_DATA";

export const SET_AFFILIATE_MODAL_DATA = "SET_AFFILIATE_MODAL_DATA";
export const SET_AFFILIATE_MODAL_DATA_SEARCH =
  "SET_AFFILIATE_MODAL_DATA_SEARCH";
export const SET_AFFILIATE_MODAL_DATA_PAGE_NO =
  "SET_AFFILIATE_MODAL_DATA_PAGE_NO";
export const SET_AFFILIATE_MODAL_DATA_LOADING =
  "SET_AFFILIATE_MODAL_DATA_LOADING";
export const SET_AFFILIATE_MODAL_DATA_SORT_ORDER =
  "SET_AFFILIATE_MODAL_DATA_SORT_ORDER";
export const SET_AFFILIATE_MODAL_DATA_SORT_FIELD =
  "SET_AFFILIATE_MODAL_DATA_SORT_FIELD";
export const SET_AFFILIATE_MODAL_DATA_PAGE_RECORD =
  "SET_AFFILIATE_MODAL_DATA_PAGE_RECORD";
export const CLEAR_AFFILIATE_MODAL_PAYLOAD = "CLEAR_AFFILIATE_MODAL_PAYLOAD";

//affiliate widget Modal
export const FETCH_AFFILIATE_WIDGET_MODAL_DATA = "FETCH_AFFILIATE_WIDGET_MODAL_DATA";
export const SET_AFFILIATE_WIDGET_MODAL_DATA = "SET_AFFILIATE_WIDGET_MODAL_DATA";
export const SET_AFFILIATE_WIDGET_MODAL_DATA_SEARCH = "SET_AFFILIATE_WIDGET_MODAL_DATA_SEARCH";
export const SET_AFFILIATE_WIDGET_MODAL_DATA_PAGE_NO = "SET_AFFILIATE_WIDGET_MODAL_DATA_PAGE_NO";
export const SET_AFFILIATE_WIDGET_MODAL_DATA_LOADING = "SET_AFFILIATE_WIDGET_MODAL_DATA_LOADING";
export const SET_AFFILIATE_WIDGET_MODAL_DATA_PAGE_RECORD = "SET_AFFILIATE_WIDGET_MODAL_DATA_PAGE_RECORD";
export const CLEAR_AFFILIATE_WIDGET_MODAL_PAYLOAD = "CLEAR_AFFILIATE_WIDGET_MODAL_PAYLOAD"

export const LIST_VIDEO_LOADING = "LIST_VIDEO_LOADING";
export const LIST_VIDEO = "LIST_VIDEO";
export const LIST_VIDEO_ERROR = "LIST_VIDEO_ERROR";

//INTAKE FORM LISTING --GOLDY

export const INTAKE_LIST_LOADING = "INTAKE_LIST_LOADING";
export const INTAKE_LIST = "INTAKE_LIST";
export const INTAKE_LIST_ERROR = "INTAKE_LIST_ERROR";
export const INTAKE_LIST_PAGE = "INTAKE_LIST_PAGE";
export const INTAKE_LIST_SIZEPER_PAGE = "INTAKE_LIST_SIZEPER_PAGE";
export const INTAKE_LIST_SEARCH = "INTAKE_LIST_SEARCH";
export const INTAKE_LIST_SORT_ORDER = "INTAKE_LIST_SORT_ORDER";
export const INTAKE_LIST_SORT_FIELD = "INTAKE_LIST_SORT_FIELD";
export const SAVE_INTAKE_DATA_LOADING = "SAVE_INTAKE_DATA_LOADING";
export const SAVE_INTAKE_DATA_SUCCESS = "SAVE_INTAKE_DATA_SUCCESS";
export const SET_INTAKE_FILTER_BY_MASTERMIND =
  "SET_INTAKE_FILTER_BY_MASTERMIND";
export const SET_INTAKE_FILTER_BY_STATUS = "SET_INTAKE_FILTER_BY_STATUS";
