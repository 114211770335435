import Curriculum from "assets/images/logo/banner/curi.png";
import Game from "assets/images/logo/banner/game.png";
import Grow from "assets/images/logo/banner/grow.png";
import Hubs from "assets/images/logo/banner/hubs.png";
import Slider from "assets/images/logo/banner/slidr.png";

export const siteDetails = siteId => {
  if (siteId === dynamicSiteId.Curriculum) {
    return { title: "Curriculum", logo: Curriculum, color: "#FF0000" };
  } else if (siteId === dynamicSiteId.Builder) {
    return { title: "Builder", logo: Grow, color: "#FFFF00" };
  } else if (siteId === dynamicSiteId.Game) {
    return { title: "Game", logo: Game, color: "#FFA500" };
  } else if (siteId === dynamicSiteId.Hubs) {
    return { title: "Hubs", logo: Hubs, color: "#0000FF" };
  } else if (siteId === dynamicSiteId.Slider) {
    return { title: "Slider", logo: Slider, color: "#00FF00" };
  } else if (siteId === dynamicSiteId.People) {
    return { title: "People", logo: Grow, color: "#A020F0" };
  } else {
    return {
      title: "",
      logo: Grow,
      color: "#556ee6",
    };
  }
};

export const sites = {
  1: "Admin",
  2: "Curriculum",
  3: "Builder",
  4: "Game",
  5: "Hubs",
  6: "Slidr",
  7: "People",
  8: "Affiliate",
};

export const sitesData = {
  1: "Account",
  2: "Grow Curriculum",
  3: "Grow Builder",
  4: "Grow Game",
  5: "Hubs",
  6: "Slidr",
  7: "Grow People",
  8: "Affiliate",
  9: "Admin",
  10: "Habit Student",
  11: "Habit Kids",
  20: "Grow Creative Board",
  13: "Kids Music",
};

export const dynamicSiteId = {
  Account: process.env.REACT_APP_ENV === "dev" ? 1 : 1,
  Curriculum: process.env.REACT_APP_ENV === "dev" ? 2 : 2,
  Builder: process.env.REACT_APP_ENV === "dev" ? 3 : 3,
  Game: process.env.REACT_APP_ENV === "dev" ? 4 : 4,
  Hubs: process.env.REACT_APP_ENV === "dev" ? 5 : 5,
  Slider: process.env.REACT_APP_ENV === "dev" ? 6 : 6,
  People: process.env.REACT_APP_ENV === "dev" ? 7 : 7,
  Affiliate: process.env.REACT_APP_ENV === "dev" ? 8 : 8,
  Admin: process.env.REACT_APP_ENV === "dev" ? 9 : 9,
  HabitStudent: process.env.REACT_APP_ENV === "dev" ? 10 : 10,
  HabitKids: process.env.REACT_APP_ENV === "dev" ? 11 : 11,
  PeopleReact: process.env.REACT_APP_ENV === "dev" ? 13 : 0,
  TvApp: process.env.REACT_APP_ENV === "dev" ? 14 : 0,
  Together: process.env.REACT_APP_ENV === "dev" ? 15 : 12,
  PeopleDonation: process.env.REACT_APP_ENV === "dev" ? 16 : 0,
  KidMusic: process.env.REACT_APP_ENV === "dev" ? 17 : 13,
  FreeTrial: process.env.REACT_APP_ENV === "dev" ? 18 : 15,
  Books: process.env.REACT_APP_ENV === "dev" ? 19 : 19,
  CreativeBoard: process.env.REACT_APP_ENV === "dev" ? 20 : 20,
  Numbers: process.env.REACT_APP_ENV === "dev" ? 0 : 14,
};

export const dynamicSiteName = Object.fromEntries(
  Object.entries(dynamicSiteId).map(([key, value]) => [value, key])
);

export const SiteNameUsingKey = {
  Account: "Account",
  Curriculum: "Grow Curriculum",
  Builder: "Grow Builder",
  Game: "Grow Games",
  Hubs: "Hubs",
  Slider: "Grow Slides",
  People: "Grow People",
  Affiliate: "Affiliate",
  Admin: "Admin",
  HabitStudent: "Habit Student",
  HabitKids: "Habit Kids",
  PeopleReact: "Grow People",
  TvApp: "Grow TV App",
  Together: "Grow Together",
  PeopleDonation: "Grow People",
  KidMusic: "Grow Kid Music",
  FreeTrial: "Free Trial Experience",
  Books: "Grow Books",
  CreativeBoard: "Grow Creative Board",
  Numbers: "Grow Numbers",
};
