import moment from "moment";
import {
  ADD_EDIT_SERVICE,
  ADD_EDIT_SERVICE_ERROR,
  ADD_EDIT_SERVICE_SUCCESS,
  ADD_EDIT_SITE,
  ADD_EDIT_SITE_ERROR,
  ADD_EDIT_SITE_SUCCESS,
  ADD_EDIT_SMS_SERVICE,
  ADD_EDIT_SMS_SERVICE_ERROR,
  ADD_EDIT_SMS_SERVICE_SUCCESS,
  CLEAR_AFFILIATE,
  CLEAR_AFFILIATE_MODAL_PAYLOAD,
  CLEAR_AFFILIATE_PAYOUT,
  CLEAR_AFFILIATE_REFERRAL,
  CLEAR_AFFILIATE_VISIT,
  CLEAR_AFFILIATE_WIDGET_MODAL_PAYLOAD,
  CLEAR_MENU_DATA,
  CLEAR_SERVICE,
  DELETE_APP_MENU,
  DELETE_APP_MENU_ERROR,
  DELETE_APP_MENU_SUCCESS,
  DELETE_EMAIL_SERVICE,
  DELETE_EMAIL_SERVICE_ERROR,
  DELETE_EMAIL_SERVICE_SUCCESS,
  DELETE_SITE_PAYMENT_SERVICE,
  DELETE_SITE_PAYMENT_SERVICE_ERROR,
  DELETE_SITE_PAYMENT_SERVICE_SUCCESS,
  FETCH_AFFILIATE_PAYOUT,
  FETCH_AFFILIATE_PAYOUT_ERROR,
  FETCH_AFFILIATE_PAYOUT_SUCCESS,
  FETCH_AFFILIATE_REFERRAL,
  FETCH_AFFILIATE_REFERRAL_ERROR,
  FETCH_AFFILIATE_REFERRAL_SUCCESS,
  FETCH_HIGHEST_CONVERTING_URLS,
  FETCH_HIGHEST_CONVERTING_URLS_ERROR,
  FETCH_HIGHEST_CONVERTING_URLS_SUCCESS,
  FETCH_LATEST_AFFILIATE_USERS,
  FETCH_LATEST_AFFILIATE_USERS_ERROR,
  FETCH_LATEST_AFFILIATE_USERS_SUCCESS,
  FETCH_MOST_VALUABLE_AFFILIATES,
  FETCH_MOST_VALUABLE_AFFILIATES_ERROR,
  FETCH_MOST_VALUABLE_AFFILIATES_SUCCESS,
  FETCH_PAYMENT_SERVICE,
  FETCH_PAYMENT_SERVICES_SUCCESS,
  FETCH_PAYMENT_SERVICE_ERROR,
  FETCH_RATE_CONFIGURATION,
  FETCH_RATE_CONFIGURATION_ERROR,
  FETCH_RATE_CONFIGURATION_SUCCESS,
  FETCH_RECENT_REFERRALS,
  FETCH_RECENT_REFERRALS_ERROR,
  FETCH_RECENT_REFERRALS_SUCCESS,
  FETCH_SERVER_CREDENTIAL_ERROR,
  FETCH_SERVER_CREDENTIAL_SUCCESS,
  FETCH_SITES,
  FETCH_SITES_SUCCESS,
  FETCH_SMS_SERVER_CREDENTIAL_ERROR,
  FETCH_SMS_SERVER_CREDENTIAL_SUCCESS,
  FILTER_TYPE_AFFILIATE,
  GET_ACTIVE_TYPE,
  GET_AFFILIATE,
  GET_AFFILIATE_ERROR,
  GET_AFFILIATE_SUCCESS,
  GET_ALL_APP_MENU,
  GET_ALL_APP_MENU_ERROR,
  GET_ALL_APP_MENU_SUCCESS,
  GET_DASHBOARD_PAGE_CONTACT,
  GET_DASHBOARD_PAGE_CONTACT_ERROR,
  GET_DASHBOARD_PAGE_CONTACT_SUCCESS,
  GET_SERVICE,
  GET_SERVICE_ERROR,
  GET_SERVICE_SUCCESS,
  GET_SMS_SERVICE,
  GET_SMS_SERVICE_ERROR,
  GET_SMS_SERVICE_SUCCESS,
  GET_SYSTEM_CONFIG,
  GET_SYSTEM_CONFIG_ERROR,
  GET_SYSTEM_CONFIG_SUCCESS,
  INTAKE_LIST,
  INTAKE_LIST_ERROR,
  INTAKE_LIST_LOADING,
  INTAKE_LIST_PAGE,
  INTAKE_LIST_SEARCH,
  INTAKE_LIST_SIZEPER_PAGE,
  INTAKE_LIST_SORT_FIELD,
  INTAKE_LIST_SORT_ORDER,
  LIST_AFFILIATE_VISIT_DATA,
  LIST_AFFILIATE_VISIT_DATA_ERROR,
  LIST_AFFILIATE_VISIT_DATA_SUCCESS,
  LIST_VIDEO,
  LIST_VIDEO_ERROR,
  LIST_VIDEO_LOADING,
  ORDER_APP_MENU,
  ORDER_APP_MENU_ERROR,
  ORDER_APP_MENU_SUCCESS,
  SAVE_APP_MENU,
  SAVE_APP_MENU_ERROR,
  SAVE_APP_MENU_SUCCESS,
  SAVE_HOME_DASHBOARD_DETAILS,
  SAVE_HOME_DASHBOARD_DETAILS_ERROR,
  SAVE_HOME_DASHBOARD_DETAILS_SUCCESS,
  SAVE_INTAKE_DATA_LOADING,
  SAVE_INTAKE_DATA_SUCCESS,
  SAVE_PAYMENT_SERVICE,
  SAVE_PAYMENT_SERVICE_ERROR,
  SAVE_PAYMENT_SERVICE_SUCCESS,
  SAVE_RATE_CONFIGURATION,
  SAVE_RATE_CONFIGURATION_ERROR,
  SAVE_RATE_CONFIGURATION_SUCCESS,
  SAVE_SYSTEM_CONFIG,
  SAVE_SYSTEM_CONFIG_ERROR,
  SAVE_SYSTEM_CONFIG_SUCCESS,
  SET_AFFILIATE_CALCULATE_LOADER,
  SET_AFFILIATE_DATE_RANGE,
  SET_AFFILIATE_FILTER,
  SET_AFFILIATE_LOADER,
  SET_AFFILIATE_MODAL_DATA,
  SET_AFFILIATE_MODAL_DATA_LOADING,
  SET_AFFILIATE_MODAL_DATA_PAGE_NO,
  SET_AFFILIATE_MODAL_DATA_PAGE_RECORD,
  SET_AFFILIATE_MODAL_DATA_SEARCH,
  SET_AFFILIATE_MODAL_DATA_SORT_FIELD,
  SET_AFFILIATE_MODAL_DATA_SORT_ORDER,
  SET_AFFILIATE_PAGE,
  SET_AFFILIATE_PAYNOW_LOADER,
  SET_AFFILIATE_PAYOUT_END_DATE,
  SET_AFFILIATE_PAYOUT_PAGE,
  SET_AFFILIATE_PAYOUT_PAYMENT_METHOD,
  SET_AFFILIATE_PAYOUT_SEARCH,
  SET_AFFILIATE_PAYOUT_SIZE_PER_PAGE,
  SET_AFFILIATE_PAYOUT_SORT_FIELD,
  SET_AFFILIATE_PAYOUT_SORT_ORDER,
  SET_AFFILIATE_PAYOUT_START_DATE,
  SET_AFFILIATE_PAYOUT_STATUS,
  SET_AFFILIATE_REFFERAL_LOADER,
  SET_AFFILIATE_SEARCH,
  SET_AFFILIATE_SIZE_PER_PAGE,
  SET_AFFILIATE_SORT_FIELD,
  SET_AFFILIATE_SORT_ORDER,
  SET_AFFILIATE_VISIT_PAGE,
  SET_AFFILIATE_VISIT_SEARCH,
  SET_AFFILIATE_VISIT_SIZE_PER_PAGE,
  SET_AFFILIATE_VISIT_SORT_FIELD,
  SET_AFFILIATE_VISIT_SORT_ORDER,
  SET_AFFILIATE_WIDGET_MODAL_DATA,
  SET_AFFILIATE_WIDGET_MODAL_DATA_LOADING,
  SET_AFFILIATE_WIDGET_MODAL_DATA_PAGE_NO,
  SET_AFFILIATE_WIDGET_MODAL_DATA_PAGE_RECORD,
  SET_AFFILIATE_WIDGET_MODAL_DATA_SEARCH,
  SET_INTAKE_FILTER_BY_MASTERMIND,
  SET_INTAKE_FILTER_BY_STATUS,
  SET_REFERRAL_PAGE,
  SET_REFERRAL_SEARCH,
  SET_REFERRAL_SIZE_PER_PAGE,
  SET_REFERRAL_SORT_FIELD,
  SET_REFERRAL_SORT_ORDER,
  SET_REFERRAL_STATUS,
  SET_REFERRAL_TYPE,
  SET_SITE_ID,
  SYSTEM_PAGE_PAGELINK,
  SYSTEM_PAGE_PAGELINK_ERROR,
  SYSTEM_PAGE_PAGELINK_SUCCESS,
} from "./actionTypes";

const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();

const initialState = {
  siteId: 0,
  paymentServices: null,
  paymentServiceLoader: false,
  service: null,
  smsService: null,
  loadingService: false,
  loadingSmsService: false,
  loadingServiceButton: false,
  loadingSmsServiceButton: false,
  siteLoader: false,
  serverCredential: [],
  smsServerCredential: [],
  loading: false,
  loadingSite: false,
  sites: [],
  appMenuList: [],
  loadingMenu: false,
  loadingMenuButton: false,
  menu: [],
  loadingOrder: false,
  paymentServiceButtonLoader: false,
  loadingPolicy: false,
  systemConfig: [],
  loadingSystemConfigButton: false,
  activeMenuType: 0,
  loadingSystemPageLink: false,
  systemPageLink: [],
  affiliate: [],
  affiliateSizePerPage: 25,
  affiliateTotalSize: 0,
  affiliatePage: 1,
  affiliateSearch: "",
  affiliateSortField: "affiliate_id",
  affiliateSortOrder: "DESC",
  affiliateLoader: false,
  affiliateButtonLoader: false,
  affiliateReferralButtonLoader: false,
  affiliatePaynowButtonLoader: false,
  affiliateCalculateButtonLoader: false,
  rateConfiguration: null,
  rateConfigurationLoader: false,
  rateConfigurationButtonLoader: false,
  latestAffiliateUsers: [],
  latestAffiliateUsersLoader: false,
  highestConvertingUrls: [],
  highestConvertingUrlsLoader: false,
  mostValuableAffiliate: [],
  mostValuableAffiliateLoader: false,
  recentReferrals: [],
  recentReferralsLoader: false,
  payout: [],
  affiliatePayoutSizePerPage: 25,
  affiliatePayoutTotalSize: 0,
  affiliatePayoutPage: 1,
  affiliatePayoutPaymentMethod: null,
  affiliatePayoutStatus: null,
  affiliatePayoutStartDate: null,
  affiliatePayoutEndDate: null,
  affiliatePayoutSearch: "",
  affiliatePayoutSortField: "affiliate_payout_id",
  affiliatePayoutSortOrder: "DESC",
  affiliatePayoutLoader: false,
  affiliateVisitData: [],
  affiliateVisitSizePerPage: 25,
  affiliateVisitTotalSize: 0,
  affiliateVisitLoader: false,
  affiliatVisitPageNumber: 1,
  affiliateVisitSearch: "",
  affiliateVisitSortField: "affiliate_visit_id",
  affiliateVisitSortOrder: "DESC",
  affiliateReferral: [],
  affiliateReferralLoader: false,
  referralPage: 1,
  referralSearch: "",
  referralSizePerPage: 25,
  referralTotalSize: 0,
  referralSortField: "affiliate_referral_id",
  referralSortOrder: "DESC",
  referralDateRange: {
    startDate,
    endDate,
  },
  referralType: "",
  referralStatus: null,
  loadingSaveSite: false,
  affiliateModalData: {
    loading: false,
    rows: [],
    count: 0,
    search: "",
    pageNo: 1,
    pageRecord: 10,
    sortOrder: "DESC",
    sortField: "created_datetime",
  },
  affiliateWidgetModalData: {
    loading: false,
    rows: [],
    count: 0,
    search: "",
    pageNo: 1,
    pageRecord: 10,
  },
  tz: "0000",
  videoLoading: false,
  videos: null,
  saveDashboardPagePayload: {},
  homeDashboardSlides: [],
  intakeFormData: {
    loading: false,
    list: [],
    search: "",
    pageNo: 1,
    sizePerPage: 10,
    totalSize: 0,
    sortOrder: "DESC",
    sortField: "created_datetime",
    filterByMasterMind: 0,
    filterByStatus: 0,
  },

  intakeEditLoader: false,
  intakeEditError: "",
  affiliateFilterType: 0,
};
const applicationsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SITE_ID:
      return {
        ...state,
        siteId: action.id,
      };

    case FETCH_PAYMENT_SERVICE:
      return {
        ...state,
        paymentServiceLoader: true,
      };
    case FETCH_PAYMENT_SERVICES_SUCCESS:
      return {
        ...state,
        paymentServices: action.payload,
        paymentServiceLoader: false,
      };
    case FETCH_PAYMENT_SERVICE_ERROR:
      return {
        ...state,
        paymentServices: action.payload,
        paymentServiceLoader: false,
      };
    case GET_DASHBOARD_PAGE_CONTACT:
      return {
        ...state,
      };
    case GET_DASHBOARD_PAGE_CONTACT_SUCCESS:
      return {
        ...state,
        saveDashboardPagePayload: action.payload,
      };
    case GET_DASHBOARD_PAGE_CONTACT_ERROR:
      return {
        ...state,
      };
    case SAVE_HOME_DASHBOARD_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case SAVE_HOME_DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        saveDashboardPagePayload: action.payload,
        loading: false,
      };
    case SAVE_HOME_DASHBOARD_DETAILS_ERROR:
      return {
        ...state,
      };
    case SAVE_PAYMENT_SERVICE:
      return {
        ...state,
        paymentServiceButtonLoader: true,
      };
    case SAVE_PAYMENT_SERVICE_SUCCESS:
      return {
        ...state,
        paymentServiceButtonLoader: false,
        paymentServices: action.payload,
      };
    case SAVE_PAYMENT_SERVICE_ERROR:
      return {
        ...state,
        paymentServiceButtonLoader: false,
      };

    case GET_SERVICE:
      return {
        ...state,
        loadingService: true,
      };
    case GET_SERVICE_SUCCESS:
      return {
        ...state,
        service: payload,
        loadingService: false,
      };
    case GET_SERVICE_ERROR:
      return {
        ...state,
        loadingService: false,
      };

    case CLEAR_SERVICE:
      return {
        ...state,
        service: null,
      };

    case ADD_EDIT_SERVICE:
      return {
        ...state,
        loadingServiceButton: true,
      };
    case ADD_EDIT_SERVICE_SUCCESS:
      return {
        ...state,
        loadingServiceButton: false,
        service: action.payload,
      };
    case ADD_EDIT_SERVICE_ERROR:
      return {
        ...state,
        loadingServiceButton: false,
      };

    case DELETE_EMAIL_SERVICE:
      return {
        ...state,
        loadingServiceButton: true,
      };
    case DELETE_EMAIL_SERVICE_SUCCESS:
      return {
        ...state,
        loadingServiceButton: false,
        service: null,
      };
    case DELETE_EMAIL_SERVICE_ERROR:
      return {
        ...state,
        loadingServiceButton: false,
      };
    case FETCH_SERVER_CREDENTIAL_SUCCESS:
      return {
        ...state,
        serverCredential: action.payload,
      };
    case FETCH_SERVER_CREDENTIAL_ERROR:
      return {
        ...state,
      };
    case DELETE_SITE_PAYMENT_SERVICE:
      return {
        ...state,
      };
    case DELETE_SITE_PAYMENT_SERVICE_SUCCESS:
      return {
        ...state,
        paymentServices: null,
      };
    case DELETE_SITE_PAYMENT_SERVICE_ERROR:
      return {
        ...state,
      };

    case FETCH_SITES:
      return {
        ...state,
        loadingSite: true,
      };
    case FETCH_SITES_SUCCESS:
      return {
        ...state,
        sites: payload,
        loadingSite: false,
      };
    case ADD_EDIT_SITE:
      return {
        ...state,
        loadingSaveSite: true,
      };
    case ADD_EDIT_SITE_SUCCESS:
      let siteInfo = action.payload;
      state.sites.map(site => {
        if (site.site_id === siteInfo.site_id) {
          site.color_code = siteInfo.color_code;
          site.description = siteInfo.description;
          site.logo = siteInfo.logo;
          site.title = siteInfo.title;
          site.url = siteInfo.url;
          site.small_logo = siteInfo.small_logo;
        }
      });
      return {
        ...state,
        loadingSaveSite: false,
      };
    case ADD_EDIT_SITE_ERROR:
      return {
        ...state,
        loadingSaveSite: false,
      };
    /** app menu list */
    case GET_ALL_APP_MENU:
      return {
        ...state,
        loadingService: true,
      };
    case GET_ALL_APP_MENU_SUCCESS:
      return {
        ...state,
        appMenuList: payload,
        loadingMenu: false,
      };
    case GET_ALL_APP_MENU_ERROR:
      return {
        ...state,
        loadingMenu: false,
      };

    case SAVE_APP_MENU:
      return {
        ...state,
        loadingMenuButton: true,
      };
    case SAVE_APP_MENU_SUCCESS:
      return {
        ...state,
        loadingMenuButton: false,
        menu: payload,
      };
    case SAVE_APP_MENU_ERROR:
      return {
        ...state,
        loadingMenuButton: false,
      };
    case DELETE_APP_MENU:
      return {
        ...state,
        loading: true,
      };
    case DELETE_APP_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_APP_MENU_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ORDER_APP_MENU:
      return {
        ...state,
        loadingOrder: true,
      };
    case ORDER_APP_MENU_SUCCESS:
      return {
        ...state,
        loadingOrder: false,
      };
    case ORDER_APP_MENU_ERROR:
      return {
        ...state,
        loadingOrder: false,
      };

    /**25-02-22 */

    case GET_SMS_SERVICE:
      return {
        ...state,
        loadingSmsService: true,
      };
    case GET_SMS_SERVICE_SUCCESS:
      return {
        ...state,
        smsService: payload,
        loadingSmsService: false,
      };
    case GET_SMS_SERVICE_ERROR:
      return {
        ...state,
        loadingSmsService: false,
      };

    case FETCH_SMS_SERVER_CREDENTIAL_SUCCESS:
      return {
        ...state,
        smsServerCredential: action.payload,
      };
    case FETCH_SMS_SERVER_CREDENTIAL_ERROR:
      return {
        ...state,
      };

    case ADD_EDIT_SMS_SERVICE:
      return {
        ...state,
        loadingSmsServiceButton: true,
      };
    case ADD_EDIT_SMS_SERVICE_SUCCESS:
      return {
        ...state,
        loadingSmsServiceButton: false,
        smsService: action.payload,
      };
    case ADD_EDIT_SMS_SERVICE_ERROR:
      return {
        ...state,
        loadingSmsServiceButton: false,
      };

    // policy
    case GET_SYSTEM_CONFIG:
      return {
        ...state,
        loadingPolicy: true,
      };
    case GET_SYSTEM_CONFIG_SUCCESS:
      return {
        ...state,
        loadingPolicy: false,
        systemConfig: payload,
      };

    case GET_SYSTEM_CONFIG_ERROR:
      return {
        ...state,
        loadingPolicy: false,
      };

    case SAVE_SYSTEM_CONFIG:
      return {
        ...state,
        loadingSystemConfigButton: true,
      };
    case SAVE_SYSTEM_CONFIG_SUCCESS:
      return {
        ...state,
        loadingSystemConfigButton: false,
      };

    case SAVE_SYSTEM_CONFIG_ERROR:
      return {
        ...state,
        loadingSystemConfigButton: false,
      };
    case GET_AFFILIATE:
      return {
        ...state,
        loadingSite: true,
        affiliateLoader: true,
      };
    case SET_AFFILIATE_LOADER:
      return {
        ...state,
        affiliateButtonLoader: payload,
      };
    case SET_AFFILIATE_REFFERAL_LOADER:
      return {
        ...state,
        affiliateReferralButtonLoader: payload,
      };
    case SET_AFFILIATE_PAYNOW_LOADER:
      return {
        ...state,
        affiliatePaynowButtonLoader: payload,
      };
    case SET_AFFILIATE_CALCULATE_LOADER:
      return {
        ...state,
        affiliateCalculateButtonLoader: payload,
      };
    case GET_AFFILIATE_SUCCESS:
      return {
        ...state,
        affiliate: payload.rows,
        loadingSite: false,
        affiliateLoader: false,
        affiliateTotalSize: payload.count,
      };
    case GET_AFFILIATE_ERROR:
      return {
        ...state,
        loadingSite: true,
        affiliateLoader: false,
      };
    case SET_AFFILIATE_PAGE:
      return {
        ...state,
        affiliatePage: payload,
      };
    case SET_AFFILIATE_FILTER:
      return {
        ...state,
        affiliateFilter: payload,
        affiliatePage: 1,
      };
    case FILTER_TYPE_AFFILIATE:
      return {
        ...state,
        affiliateFilterType: payload,
      };

    case SET_AFFILIATE_SEARCH:
      return {
        ...state,
        affiliateSearch: payload,
        affiliatePage: 1,
      };
    case SET_AFFILIATE_SIZE_PER_PAGE:
      return {
        ...state,
        affiliateSizePerPage: payload,
      };
    case SET_AFFILIATE_SORT_FIELD:
      return {
        ...state,
        affiliateSortField: payload,
      };
    case SET_AFFILIATE_SORT_ORDER:
      return {
        ...state,
        affiliateSortOrder: payload,
      };
    case CLEAR_AFFILIATE:
      return {
        ...state,
        affiliatePage: 1,
        affiliateSearch: "",
        affiliateSizePerPage: 25,
        affiliateTotalSize: 0,
        affiliateSortField: "affiliate_id",
        affiliateSortOrder: "DESC",
        affiliateFilter: "",
      };
    case GET_ACTIVE_TYPE:
      return {
        ...state,
        activeMenuType: payload,
      };

    case SYSTEM_PAGE_PAGELINK:
      return {
        ...state,
        loadingSystemPageLink: true,
      };

    case SYSTEM_PAGE_PAGELINK_SUCCESS:
      return {
        ...state,
        loadingSystemPageLink: false,
        systemPageLink: payload,
      };

    case SYSTEM_PAGE_PAGELINK_ERROR:
      return {
        ...state,
        loadingSystemPageLink: false,
      };

    case FETCH_RATE_CONFIGURATION:
      return {
        ...state,
        rateConfigurationLoader: true,
      };
    case FETCH_RATE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        rateConfiguration: action.payload,
        rateConfigurationLoader: false,
      };
    case FETCH_RATE_CONFIGURATION_ERROR:
      return {
        ...state,
        rateConfiguration: action.payload,
        rateConfigurationLoader: false,
      };
    case SAVE_RATE_CONFIGURATION:
      return {
        ...state,
        rateConfigurationButtonLoader: true,
      };
    case SAVE_RATE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        rateConfigurationButtonLoader: false,
        rateConfiguration: action.payload,
      };
    case SAVE_RATE_CONFIGURATION_ERROR:
      return {
        ...state,
        rateConfigurationButtonLoader: false,
      };
    case FETCH_LATEST_AFFILIATE_USERS:
      return {
        ...state,
        latestAffiliateUsersLoader: true,
      };
    case FETCH_LATEST_AFFILIATE_USERS_SUCCESS:
      return {
        ...state,
        latestAffiliateUsersLoader: false,
        latestAffiliateUsers: action.payload,
      };
    case FETCH_LATEST_AFFILIATE_USERS_ERROR:
      return {
        ...state,
        latestAffiliateUsersLoader: true,
      };
    case FETCH_HIGHEST_CONVERTING_URLS:
      return {
        ...state,
        highestConvertingUrlsLoader: true,
      };
    case FETCH_HIGHEST_CONVERTING_URLS_SUCCESS:
      return {
        ...state,
        highestConvertingUrls: action.payload,
        highestConvertingUrlsLoader: false,
      };
    case FETCH_HIGHEST_CONVERTING_URLS_ERROR:
      return {
        ...state,
        highestConvertingUrlsLoader: true,
      };
    case FETCH_MOST_VALUABLE_AFFILIATES:
      return {
        ...state,
        mostValuableAffiliateLoader: true,
      };
    case FETCH_MOST_VALUABLE_AFFILIATES_SUCCESS:
      return {
        ...state,
        mostValuableAffiliate: action.payload,
        mostValuableAffiliateLoader: false,
      };
    case FETCH_MOST_VALUABLE_AFFILIATES_ERROR:
      return {
        ...state,
        mostValuableAffiliateLoader: true,
      };
    case FETCH_RECENT_REFERRALS:
      return {
        ...state,
        recentReferralsLoader: true,
      };
    case FETCH_RECENT_REFERRALS_SUCCESS:
      return {
        ...state,
        recentReferrals: action.payload,
        recentReferralsLoader: false,
      };
    case FETCH_RECENT_REFERRALS_ERROR:
      return {
        ...state,
        recentReferralsLoader: true,
      };
    case FETCH_AFFILIATE_PAYOUT:
      return {
        ...state,
        affiliatePayoutLoader: true,
      };
    case FETCH_AFFILIATE_PAYOUT_SUCCESS:
      return {
        ...state,
        payout: payload.rows,
        affiliatePayoutLoader: false,
        affiliatePayoutTotalSize: payload.count,
      };
    case FETCH_AFFILIATE_PAYOUT_ERROR:
      return {
        ...state,
        affiliatePayoutLoader: true,
      };
    case SET_AFFILIATE_PAYOUT_PAGE:
      return {
        ...state,
        affiliatePayoutPage: payload,
      };
    case SET_AFFILIATE_PAYOUT_PAYMENT_METHOD:
      return {
        ...state,
        affiliatePayoutPaymentMethod: payload,
        affiliatePayoutPage: 1,
      };
    case SET_AFFILIATE_PAYOUT_STATUS:
      return {
        ...state,
        affiliatePayoutStatus: payload,
        affiliatePayoutPage: 1,
      };
    case SET_AFFILIATE_PAYOUT_START_DATE:
      return {
        ...state,
        affiliatePayoutStartDate: payload,
        affiliatePayoutPage: 1,
      };
    case SET_AFFILIATE_PAYOUT_END_DATE:
      return {
        ...state,
        affiliatePayoutEndDate: payload,
        affiliatePayoutPage: 1,
      };
    case SET_AFFILIATE_PAYOUT_SEARCH:
      return {
        ...state,
        affiliatePayoutSearch: payload,
        affiliatePayoutPage: 1,
      };
    case SET_AFFILIATE_PAYOUT_SIZE_PER_PAGE:
      return {
        ...state,
        affiliatePayoutSizePerPage: payload,
      };
    case SET_AFFILIATE_PAYOUT_SORT_FIELD:
      return {
        ...state,
        affiliatePayoutSortField: payload,
      };
    case SET_AFFILIATE_PAYOUT_SORT_ORDER:
      return {
        ...state,
        affiliatePayoutSortOrder: payload,
      };

    case CLEAR_AFFILIATE_PAYOUT:
      return {
        ...state,
        affiliatePayoutPage: 1,
        affiliatePayoutSearch: "",
        affiliatePayoutSizePerPage: 25,
        affiliatePayoutTotalSize: 0,
        affiliatePayoutSortField: "affiliate_payout_id",
        affiliatePayoutSortOrder: "DESC",
        affiliatePayoutPaymentMethod: null,
        affiliatePayoutStatus: null,
        affiliatePayoutStartDate: null,
        affiliatePayoutEndDate: null,
      };

    // Affiliate Visit
    case LIST_AFFILIATE_VISIT_DATA:
      return {
        ...state,
        affiliateVisitLoader: true,
      };
    case LIST_AFFILIATE_VISIT_DATA_SUCCESS:
      return {
        ...state,
        affiliateVisitData: payload.rows,
        affiliateVisitLoader: false,
        affiliateVisitTotalSize: action.payload.count,
      };
    case LIST_AFFILIATE_VISIT_DATA_ERROR:
      return {
        ...state,
        affiliateVisitLoader: false,
      };
    case SET_AFFILIATE_VISIT_PAGE:
      return {
        ...state,
        affiliatVisitPageNumber: payload,
      };
    case SET_AFFILIATE_VISIT_SEARCH:
      return {
        ...state,
        affiliateVisitSearch: payload,
        affiliatVisitPageNumber: 1,
      };
    case SET_AFFILIATE_VISIT_SIZE_PER_PAGE:
      return {
        ...state,
        affiliateVisitSizePerPage: payload,
      };
    case SET_AFFILIATE_VISIT_SORT_FIELD:
      return {
        ...state,
        affiliateVisitSortField: payload,
      };
    case SET_AFFILIATE_VISIT_SORT_ORDER:
      return {
        ...state,
        affiliateVisitSortOrder: payload,
      };

    case CLEAR_AFFILIATE_VISIT:
      return {
        ...state,
        affiliatVisitPageNumber: 1,
        affiliateVisitSearch: "",
        affiliateVisitSizePerPage: 25,
        affiliateVisitTotalSize: 0,
        affiliateVisitSortField: "affiliate_visit_id",
        affiliateVisitSortOrder: "DESC",
      };

    //Affiliate Referral
    case FETCH_AFFILIATE_REFERRAL:
      return {
        ...state,
        affiliateReferralLoader: true,
      };
    case FETCH_AFFILIATE_REFERRAL_SUCCESS:
      return {
        ...state,
        affiliateReferral: payload.data,
        affiliateReferralLoader: false,
        referralTotalSize: payload.count,
      };
    case FETCH_AFFILIATE_REFERRAL_ERROR:
      return {
        ...state,
        affiliateReferralLoader: false,
      };

    case SET_REFERRAL_PAGE:
      return {
        ...state,
        referralPage: payload,
      };
    case SET_REFERRAL_SEARCH:
      return {
        ...state,
        referralSearch: payload,
        referralPage: 1,
      };
    case SET_REFERRAL_SIZE_PER_PAGE:
      return {
        ...state,
        referralSizePerPage: payload,
      };
    case SET_REFERRAL_SORT_FIELD:
      return {
        ...state,
        referralSortField: payload,
      };
    case SET_REFERRAL_SORT_ORDER:
      return {
        ...state,
        referralSortOrder: payload,
      };
    case SET_AFFILIATE_DATE_RANGE:
      return {
        ...state,
        referralDateRange: {
          ...state.referralDateRange,
          ...payload,
        },
      };
    case SET_REFERRAL_STATUS:
      return {
        ...state,
        referralStatus: payload,
        referralPage: 1,
      };
    case SET_REFERRAL_TYPE:
      return {
        ...state,
        referralType: payload,
        referralPage: 1,
      };
    case CLEAR_AFFILIATE_REFERRAL:
      return {
        ...state,
        referralPage: 1,
        referralSearch: "",
        referralSizePerPage: 25,
        referralTotalSize: 0,
        referralSortField: "affiliate_referral_id",
        referralSortOrder: "DESC",
        referralType: "",
        referralStatus: null,
      };

    case CLEAR_MENU_DATA:
      return {
        ...state,
        appMenuList: [],
      };
    case SET_AFFILIATE_MODAL_DATA:
      return {
        ...state,
        affiliateModalData: {
          ...state.affiliateModalData,
          loading: false,
          rows: action.payload.rows,
          count: action.payload.count,
        },
      };
    case SET_AFFILIATE_MODAL_DATA_PAGE_NO:
      return {
        ...state,
        affiliateModalData: {
          ...state.affiliateModalData,
          pageNo: action.payload,
        },
      };
    case SET_AFFILIATE_MODAL_DATA_PAGE_RECORD:
      return {
        ...state,
        affiliateModalData: {
          ...state.affiliateModalData,
          pageRecord: action.payload,
        },
      };
    case SET_AFFILIATE_MODAL_DATA_SORT_FIELD:
      return {
        ...state,
        affiliateModalData: {
          ...state.affiliateModalData,
          sortField: action.payload,
        },
      };
    case SET_AFFILIATE_MODAL_DATA_SORT_ORDER:
      return {
        ...state,
        affiliateModalData: {
          ...state.affiliateModalData,
          sortOrder: action.payload,
        },
      };
    case SET_AFFILIATE_MODAL_DATA_SEARCH:
      return {
        ...state,
        affiliateModalData: {
          ...state.affiliateModalData,
          search: action.payload,
        },
      };
    case SET_AFFILIATE_MODAL_DATA_LOADING:
      return {
        ...state,
        affiliateModalData: {
          ...state.affiliateModalData,
          loading: action.payload,
        },
      };
    case CLEAR_AFFILIATE_MODAL_PAYLOAD:
      return {
        ...state,
        affiliateModalData: {
          ...state.affiliateModalData,
          pageNo: 1,
        },
      };
    case SET_AFFILIATE_WIDGET_MODAL_DATA:
      return {
        ...state,
        affiliateWidgetModalData: {
          ...state.affiliateWidgetModalData,
          loading: false,
          rows: action.payload.rows,
          count: action.payload.count,
        },
      };
    case SET_AFFILIATE_WIDGET_MODAL_DATA_PAGE_NO:
      return {
        ...state,
        affiliateWidgetModalData: {
          ...state.affiliateWidgetModalData,
          pageNo: action.payload,
        },
      };
    case SET_AFFILIATE_WIDGET_MODAL_DATA_PAGE_RECORD:
      return {
        ...state,
        affiliateWidgetModalData: {
          ...state.affiliateWidgetModalData,
          pageRecord: action.payload,
        },
      };
    case SET_AFFILIATE_WIDGET_MODAL_DATA_SEARCH:
      return {
        ...state,
        affiliateWidgetModalData: {
          ...state.affiliateWidgetModalData,
          search: action.payload,
        },
      };
    case SET_AFFILIATE_WIDGET_MODAL_DATA_LOADING:
      return {
        ...state,
        affiliateWidgetModalData: {
          ...state.affiliateWidgetModalData,
          loading: action.payload,
        },
      };
    case CLEAR_AFFILIATE_WIDGET_MODAL_PAYLOAD:
      return {
        ...state,
        affiliateWidgetModalData: {
          ...state.affiliateWidgetModalData,
          loading: false,
          rows: [],
          count: 0,
          search: "",
          pageNo: 1,
          pageRecord: 10,
        },
      };
    case LIST_VIDEO_LOADING:
      return {
        ...state,
        videoLoading: true,
      };
    case LIST_VIDEO:
      return {
        ...state,
        videoLoading: false,
        videos: payload,
      };
    case LIST_VIDEO_ERROR:
      return {
        ...state,
        videoLoading: false,
      };
    case INTAKE_LIST_LOADING:
      return {
        ...state,
        intakeFormData: {
          ...state.intakeFormData,
          loading: true,
        },
      };
    case INTAKE_LIST:
      return {
        ...state,
        intakeFormData: {
          ...state.intakeFormData,
          loading: false,
          list: action.payload.data,
          totalSize: action.payload.count,
        },
      };
    case INTAKE_LIST_ERROR:
      return {
        ...state,
        intakeFormData: {
          ...state.intakeFormData,
          loading: false,
        },
      };
    case INTAKE_LIST_PAGE:
      return {
        ...state,
        intakeFormData: {
          ...state.intakeFormData,
          loading: false,
          pageNo: payload,
        },
      };
    case INTAKE_LIST_SIZEPER_PAGE:
      return {
        ...state,
        intakeFormData: {
          ...state.intakeFormData,
          loading: false,
          sizePerPage: payload,
        },
      };

    case INTAKE_LIST_SEARCH:
      return {
        ...state,
        intakeFormData: {
          ...state.intakeFormData,
          search: payload,
        },
      };
    case INTAKE_LIST_SORT_FIELD:
      return {
        ...state,
        intakeFormData: {
          ...state.intakeFormData,
          sortField: payload,
        },
      };

    case INTAKE_LIST_SORT_ORDER:
      return {
        ...state,
        intakeFormData: {
          ...state.intakeFormData,
          sortOrder: payload,
        },
      };
    case SAVE_INTAKE_DATA_LOADING:
      return {
        ...state,
        intakeEditLoader: true,
      };

    case SAVE_INTAKE_DATA_SUCCESS:
      return {
        ...state,
        intakeEditLoader: false,
      };
    case SET_INTAKE_FILTER_BY_MASTERMIND:
      return {
        ...state,
        intakeFormData: {
          ...state.intakeFormData,
          filterByMasterMind: payload,
        },
      };
    case SET_INTAKE_FILTER_BY_STATUS:
      return {
        ...state,
        intakeFormData: {
          ...state.intakeFormData,
          filterByStatus: payload,
        },
      };

    default:
      return state;
  }
};
export default applicationsReducer;
